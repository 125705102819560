import type { IDadataSuggestionCity } from '@/components/ui/fields/SearchCitiesWithSuggestions/interfaces'
import { financingProductsNames } from '@/constants/financing'
import { EMethodValidateRegion } from '@/logic/financing/enums'
import { FinancingService } from '@/services/FinancingService'
import type { EFinancingProductId } from '@/types'
import { EFinancingOfferType } from '@/types'
import { messages } from './messages'

const financingService = new FinancingService()

export const validateAvailableRegionsForFinancingProduct = (
  errorCustomMessage: string,
  productIds: EFinancingProductId[],
  method = EMethodValidateRegion.CODE,
) => {
  let regionsLoaded = false
  let cachedRegions: Array<string | number> = []

  const fetchRegions = async () => {
    if (regionsLoaded) {
      return cachedRegions
    }

    const requests = productIds.map(productId => financingService.getAvailableRegions(productId))
    const responses = await Promise.allSettled(requests)

    cachedRegions = responses.reduce<Array<string | number>>((regions, response) => {
      if (response.status === 'fulfilled') {
        const codes = method === EMethodValidateRegion.ISO_CODE ? response.value.approvedRegionsIso : response.value.approvedRegions

        return regions.concat(codes || [])
      }

      return regions
    }, [])

    regionsLoaded = responses.every(response => response.status === 'fulfilled')

    return cachedRegions
  }

  const isContainsValue = async (value: number | string | IDadataSuggestionCity) => {
    const availableRegions = await fetchRegions()

    if (isContainISOValue(value)) {
      if (!availableRegions.includes(value.regionISOCode)) {
        return errorCustomMessage
      }
    } else if (!availableRegions.includes(+value)) {
      return errorCustomMessage
    }

    return undefined
  }

  return isContainsValue
}

function isContainISOValue(value: unknown): value is IDadataSuggestionCity {
  return (value as IDadataSuggestionCity)?.regionISOCode !== undefined
}

export const getCommentFieldForConsulting = (productId?: EFinancingProductId, noEscort?: boolean) => {
  if (!productId) return ''

  const locationRequest = financingProductsNames[productId]

  return locationRequest ? `${locationRequest}${noEscort ? '' : `, ${messages.escort}`}` : ''
}

export const checkRegionForMFO = (regionValue: number, regionsAvailableExpressMFO: number[], offerType: string) => {
  if (
    (EFinancingOfferType.EXPRESS_MFO && regionsAvailableExpressMFO.includes(regionValue)) ||
    EFinancingOfferType.EXPRESS_MFO !== offerType
  ) {
    return true
  }

  return 'Данный регион недоступен для кредитования. Выберите другой регион или другой кредитный продукт'
}
