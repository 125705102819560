import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetParticipantInfoQueryResponse, GetParticipantInfoQueryParams } from "../types/GetParticipantInfo";

export const getParticipantInfoQueryKey = (params: GetParticipantInfoQueryParams) =>     [{url:`/api/purchase-tenders/v1/show_participant-info`},...(params ? [params] : [])] as const;
      export function getParticipantInfoQueryOptions <TData = GetParticipantInfoQueryResponse, TError = unknown>(params: GetParticipantInfoQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getParticipantInfoQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/purchase-tenders/v1/show_participant-info`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/purchase-tenders/v1/show_participant-info
 */

export function useGetParticipantInfo <TData = GetParticipantInfoQueryResponse, TError = unknown>(params: GetParticipantInfoQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getParticipantInfoQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getParticipantInfoQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
