import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { SaveDocumentsForTenderMutationRequest, SaveDocumentsForTenderMutationResponse } from "../../types/SaveDocumentsForTender";

/**
     * @link /api/purchase-tenders/v1/save_documents
     */
export async function saveDocumentsForTender (data: SaveDocumentsForTenderMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SaveDocumentsForTenderMutationResponse>["data"]> {
    const { data: resData } = await client<SaveDocumentsForTenderMutationResponse, SaveDocumentsForTenderMutationRequest>({
        method: "post",
        url: `/api/purchase-tenders/v1/save_documents`,
        data,
        ...options
    });
    
    return resData;
};