import type { TDatePickerValue } from '@frontend/pole-ui/lib/components/DatePicker'
import { isSameMonth, isSameYear } from 'date-fns'
import type { FieldValidator } from 'final-form'
import type { FieldValue } from 'firebase/firestore/lite'
import { EMAIL_RE } from '@/constants/regex'
import { normalizePhoneNumber } from '../formatUtils'
import { messages } from './messages'

export const isFieldRequired = <T>(value: T) =>
  typeof value !== 'undefined' && String(value)?.length ? undefined : messages.error.required

export const isFileGreaterThenMb = (file: Pick<File, 'size'>, maxFileSizeInMB: number): boolean => {
  const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB

  return file.size > maxFileSizeInKB
}

export const isSameMonthInYear = (value: TDatePickerValue): string | undefined => {
  if (!Array.isArray(value)) return undefined

  const [start = '', end = ''] = value
  const startDate = new Date(start)
  const endDate = new Date(end)

  return isSameMonth(startDate, endDate) && isSameYear(startDate, endDate) ? undefined : messages.error.invalidCurrentMonth
}

export const hasMinValue =
  (min: number, errorDescription = messages.error.incorrectMinValue) =>
  (value: string): string | undefined =>
    typeof value === 'undefined' || Number(value) >= min ? undefined : errorDescription

export const hasMaxValue =
  (max: number | undefined, errorDescription = messages.error.incorrectMaxValue) =>
  (value: string): string | undefined => {
    if (!max) return undefined

    return !value || Number(value) <= max ? undefined : errorDescription
  }

export const rangeOfValues =
  (
    min: number,
    max: number,
    minErrorDescription = messages.error.incorrectMinValue,
    maxErrorDescription = messages.error.incorrectMaxValue,
  ) =>
  (value: string): string | undefined => {
    if (Number(value) < min) return minErrorDescription
    if (Number(value) > max) return maxErrorDescription

    return undefined
  }

export const numbersAfterFloatValid =
  (numAfterDot: number, errorDescription = messages.error.numbersAfterDot) =>
  (value: string): string | undefined | void => {
    if (!value) return undefined
    const secondPart = value.split('.')[1]
    if (secondPart?.length && secondPart.length > numAfterDot) return errorDescription

    return undefined
  }

export const isPhoneNumber = ((value = ''): string | undefined => {
  if (!value) return undefined

  const phoneNumber = normalizePhoneNumber(String(value))

  return phoneNumber.length === 11 ? undefined : messages.error.shouldBeInPhoneNumberFormat
}) satisfies FieldValidator<unknown>

export const isValidEmail = (value = ''): string | undefined => {
  if (!value) return undefined

  return EMAIL_RE.test(String(value)) ? undefined : messages.error.shouldBeInEmailFormat
}

export const isNeededValueLength =
  (neededLength: number): FieldValidator<string> =>
  (value = '') =>
    value.length === neededLength ? undefined : messages.error.required

export const composeValidators =
  <T>(...validators: FieldValidator<T>[]) =>
  (value: T, allValues: object) =>
    validators.reduce((error: string | undefined, validator) => error || validator(value, allValues), undefined)

export const getValidateFunction = <T>(getValidators: () => FieldValidator<T>[]) => {
  const validators = getValidators()
  let validate
  if (validators.length) validate = composeValidators(...validators)

  return validate
}

export const isCheckboxRequired: FieldValidator<boolean> = value => (value ? undefined : messages.error.required)
export const isAutosuggestFieldRequired =
  (errorCustomMessage = messages.error.required) =>
  (value: FieldValue): unknown | Promise<unknown> =>
    value ? undefined : errorCustomMessage

type TWithLength = {
  readonly length: number
}

export const isMinLength =
  <T extends TWithLength>(minLength: number): FieldValidator<T> =>
  (value: T | undefined) =>
    value != null && value.length < minLength ? messages.error.minLength : undefined

export const isMaxLength =
  <T extends TWithLength>(maxLength: number): FieldValidator<T> =>
  (value: T | undefined) =>
    value != null && value.length > maxLength ? messages.error.maxLength : undefined

export const getStoreValidator =
  (isRequired: boolean) =>
  (value: unknown): string | undefined =>
    isRequired && (!value || value === 'placeholder') ? messages.error.requiredStoreId : undefined

const isFormValuesWithEmptyDadataResponseFlag = (values: unknown): values is { __shouldShowEmptyDadataResponse__: boolean } => {
  const formValues = (values || {}) as { __shouldShowEmptyDadataResponse__: boolean }

  return '__shouldShowEmptyDadataResponse__' in formValues
}

export const autosuggestOrganizationProfileValidator: (defaultError?: string) => FieldValidator<unknown> =
  (defaultError = messages.error.incorrectInn) =>
  (value, allValues) => {
    if (isFormValuesWithEmptyDadataResponseFlag(allValues) && allValues['__shouldShowEmptyDadataResponse__'])
      return messages.error.emptyDadataResponse

    if (!value) return defaultError

    return undefined
  }
