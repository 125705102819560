import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateValueFieldsMutationRequest, UpdateValueFieldsMutationResponse, UpdateValueFieldsQueryParams } from "../../types/UpdateValueFields";

/**
     * @link /api/purchase-tenders/v1/value-fields
     */
export async function updateValueFields (data: UpdateValueFieldsMutationRequest, params: UpdateValueFieldsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateValueFieldsMutationResponse>["data"]> {
    const { data: resData } = await client<UpdateValueFieldsMutationResponse, UpdateValueFieldsMutationRequest>({
        method: "post",
        url: `/api/purchase-tenders/v1/value-fields`,
        params,
        data,
        ...options
    });
    
    return resData;
};