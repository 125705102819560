import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetBidsBase64StringQueryResponse, GetBidsBase64StringPathParams } from "../../types/GetBidsBase64String";

/**
     * @link /api/purchase-tenders/v1/:tenderId/bids_list
     */
export async function getBidsBase64String (tenderId: GetBidsBase64StringPathParams["tenderId"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetBidsBase64StringQueryResponse>["data"]> {
    const { data: resData } = await client<GetBidsBase64StringQueryResponse>({
        method: "get",
        url: `/api/purchase-tenders/v1/${tenderId}/bids_list`,
        ...options
    });
    
    return resData;
};