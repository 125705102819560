import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetExcelTemplateQueryResponse, GetExcelTemplatePathParams } from "../types/GetExcelTemplate";

export const getExcelTemplateQueryKey = (categoryId: GetExcelTemplatePathParams["categoryId"]) =>     [{url:`/api/purchase-tenders/v1/excelTemplate/${categoryId}`,params:{categoryId:categoryId}}] as const;
      export function getExcelTemplateQueryOptions <TData = GetExcelTemplateQueryResponse, TError = unknown>(categoryId: GetExcelTemplatePathParams["categoryId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getExcelTemplateQueryKey(categoryId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/purchase-tenders/v1/excelTemplate/${categoryId}`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/purchase-tenders/v1/excelTemplate/:categoryId
 */

export function useGetExcelTemplate <TData = GetExcelTemplateQueryResponse, TError = unknown>(categoryId: GetExcelTemplatePathParams["categoryId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getExcelTemplateQueryKey(categoryId, );

  const query = useQuery<TData, TError>({
    ...getExcelTemplateQueryOptions<TData, TError>(categoryId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
