import type { ResponseConstructorTemplateDto } from "./ResponseConstructorTemplateDto";

export enum GetPositionsTemplateQueryParamsPositionType {
    "SPECIFIC_PRODUCT" = "SPECIFIC_PRODUCT",
    "SELECTION_BY_CHARACTERISTICS" = "SELECTION_BY_CHARACTERISTICS"
}
export type GetPositionsTemplateQueryParams = {
    /**
     * @type string
    */
    positionType: GetPositionsTemplateQueryParamsPositionType;
    /**
     * @type string
    */
    categoryId: string;
    /**
     * @type string | undefined
    */
    type?: string;
};

/**
 * @description OK
*/
export type GetPositionsTemplateQueryResponse = ResponseConstructorTemplateDto;
