
export enum PurchaseItemValueDtoOutputType {
    "TEXT" = "TEXT",
    "NUMBER" = "NUMBER",
    "DATE" = "DATE"
}
export type PurchaseItemValueDto = {
    /**
     * @type string
    */
    value: string;
    /**
     * @type string
    */
    outputType: PurchaseItemValueDtoOutputType;
};
