import { EColor } from '@frontend/pole-ui/lib/common/constants'
import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import classNames from 'classnames'
import omit from 'lodash/omit'
import { ERegistrationRequestStatus } from '@/api/constants'
import type {
  ICustomizedFormFieldProps,
  ICustomizedFormStepProps,
  TCustomizedFormStepField,
} from '@/components/composed/forms/CustomizedForms/interfaces'
import { LOAN_PURPOSE_FIELD_NAMES } from '@/components/ui/fields/LoanPurposeSelect/constants'
import { getHumanReadablePurposes } from '@/components/ui/fields/LoanPurposeSelect/helpers'
import type { IPolicyAgreementProps } from '@/components/ui/fields/PolicyAgreement/types'
import { EDirectoryRegions } from '@/components/ui/fields/RegionSelect/constants'
import { isCitySuggestionFormDadata } from '@/components/ui/fields/SearchCitiesWithSuggestions/typeguard'
import type { ITextInputProps } from '@/components/ui/fields/TextInput/interfaces'
import type { ELeasingFieldName, ETinkoffFieldName } from '@/logic/financing/enums'
import { EMethodValidateRegion } from '@/logic/financing/enums'
import { validateAvailableRegionsForFinancingProduct } from '@/logic/financing/helpers'
import type { IApplyingFinancingOfferRequestBody, IFinancingOfferFormValues } from '@/logic/financing/interfaces'
import { submitButtonEventPayload, submitTinkoffCreditFormEventPayload, VTBLeasingClickGtmEventPayload } from '@/logic/metrika/constants'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { EFinancingOfferType, EFinancingProductId } from '@/types'
import { ELegalDocumentsRoute } from '@/types/ELegalDocumentsRoute'
import { formatLocalizedString } from '@/utils/formatUtils'
import { createRouterLink } from '@/utils/url'
import { hasMaxValue } from '@/utils/validators'
import { messages } from './messages'

export const requestStatusName = 'applyFinancingOffer'

export const LeasingField: Record<ELeasingFieldName, TCustomizedFormStepField> = {
  fullName: {
    name: 'fullName',
    labelId: messages.leasingOffer.label.fullName,
    isRequired: true,
    isDisabled: true,
  },
  phone: {
    name: 'phone',
    type: 'tel',
    isRequired: true,
    isDisabled: true,
  },
  email: {
    name: 'email',
    type: 'email',
    labelId: messages.leasingOffer.label.email,
    isRequired: true,
  },
  organizationName: {
    name: 'organizationName',
    labelId: messages.leasingOffer.label.organizationName,
    isRequired: true,
    isDisabled: true,
  },
  organizationInn: {
    name: 'organizationInn',
    labelId: messages.leasingOffer.label.organizationInn,
    isRequired: true,
    isDisabled: true,
  },
  comment: {
    name: 'comment',
    labelId: messages.leasingOffer.label.comment,
    type: 'textArea',
  },
  city: {
    fieldType: 'searchCitiesWithSuggestions',
    valueFormattingVariant: 'all',
    apiType: 'dadata',
    isRequired: true,
  },
  cityName: {
    fieldType: 'searchCitiesWithSuggestions',
    valueFormattingVariant: 'name',
    name: 'city',
    isRequired: true,
    apiType: 'dadata',
  },
  policyAgreement: {
    name: 'agreement',
    fieldType: 'policyAgreement',
    text: messages.leasing.field.agreement.label,
    textClassName: 'text_small',
    linkToAgreement: {
      className: 'underline underline_green color_green',
    },
  },
}

export const creditOfferSteps: Omit<ICustomizedFormStepProps, 'errorMessageId' | 'titleId'> = {
  fields: [
    {
      name: 'phone',
      type: 'tel',
      isRequired: true,
      isDisabled: true,
    },
    {
      name: 'loanAmount.value',
      minValue: 1,
      minValueErrorMessageId: 'incorrectMinValue',
      type: 'price',
      labelId: messages.creditOfferModal.label.loanAmount,
      isRequired: true,
    },
    {
      name: 'fullName',
      labelId: messages.creditOfferModal.label.fullName,
      isRequired: true,
      isDisabled: true,
      isHidden: true,
    },
    {
      name: 'organizationInn',
      labelId: messages.creditOfferModal.label.organizationInn,
      isRequired: true,
      isDisabled: true,
      isHidden: true,
    },
    {
      name: 'email',
      type: 'email',
      labelId: messages.creditOfferModal.label.email,
      isRequired: true,
      isDisabled: true,
      isHidden: true,
    },
    {
      name: 'city',
      labelId: messages.creditOfferModal.label.city,
    },
    {
      name: 'comment',
      labelId: messages.creditOfferModal.label.comment,
      type: 'textArea',
    },
    {
      name: 'agreement',
      labelId: messages.creditOfferModal.label.agreement,
      fieldType: 'checkbox',
      isRequired: true,
      activeControlStyle: EColor.WHITE,
      borderColorStyle: EColor.LIGHT_GRAY,
      markColor: 'green',
    },
  ],
  submitButton: {
    textId: messages.creditOfferModal.submitButton,
    animatedContainerClass: 'financing-offer-form-button-animated-container',
    modifiers: ['white-darkGreen'],
    onClick: () => pushFormattedGtmEvent(submitButtonEventPayload),
  },
}

export const baseLeasingOfferStep: Omit<ICustomizedFormStepProps, 'fields'> = {
  errorMessageId: messages.leasingOffer.error,
  submitButton: {
    animatedContainerClass: 'financing-offer-form-button-animated-container',
    textId: messages.leasingOffer.submitButton,
    modifiers: ['white-darkGreen'],
  },
  titleId: messages.leasingOffer.title,
}

const LOAN_AGREEMENT = {
  fieldType: 'policyAgreement',
  name: 'agreement',
  text: '',
  textClassName: 'text_small',
  linkToAgreement: {
    className: 'underline',
  },
} satisfies Readonly<IPolicyAgreementProps & ICustomizedFormFieldProps>

export const TinkoffField: Record<ETinkoffFieldName, TCustomizedFormStepField> = {
  fullName: {
    name: 'fullName',
    labelId: messages.tinkoffOffer.label.fullName,
    isRequired: true,
    isDisabled: false,
  },
  phone: {
    name: 'phone',
    labelId: messages.tinkoffOffer.label.phone,
    type: 'tel',
    isRequired: true,
    isDisabled: false,
  },
  organizationInn: {
    name: 'organizationInn',
    labelId: messages.tinkoffOffer.label.organizationInn,
    isRequired: true,
    isDisabled: false,
  },
  organizationName: {
    name: 'organizationName',
    labelId: messages.tinkoffOffer.label.organizationName,
    isRequired: true,
    isDisabled: false,
  },
  loanAmount: {
    name: 'loanAmount.value',
    type: 'price',
    labelId: messages.tinkoffOffer.label.loanAmount,
    isRequired: true,
  },
  policyAgreement: {
    ...LOAN_AGREEMENT,
    text: messages.advancePaymentOffer.label.tinkoff.text,
  },
}

const LOAN_PURPOSE_FIELD: TCustomizedFormStepField = {
  name: 'loanPurpose',
  fieldType: 'loanPurposeSelect',
  className: 'financing-offer-form-container__control',
  errorClassName: 'landingApplyingOfferForm__loanPurposeError error-message',
  isRequired: true,
}

export const ApplyingOfferFormSteps: Partial<Record<EFinancingOfferType, ICustomizedFormStepProps[]>> = {
  [EFinancingOfferType.LEASING_BALTIYSKIY]: [
    {
      ...baseLeasingOfferStep,
      fields: [
        { ...LeasingField.fullName, isHidden: true },
        LeasingField.phone,
        LeasingField.email,
        LeasingField.cityName,
        LeasingField.comment,
        { ...LeasingField.organizationName, isHidden: true },
        { ...LeasingField.organizationInn, isHidden: true },
        {
          ...LeasingField.policyAgreement,
        },
      ],
      submitButton: baseLeasingOfferStep.submitButton,
    },
  ],
  [EFinancingOfferType.LEASING_ALFA]: [
    {
      ...baseLeasingOfferStep,
      fields: [
        { ...LeasingField.fullName, isHidden: true },
        LeasingField.phone,
        LeasingField.email,
        LeasingField.city,
        { ...LeasingField.comment, isRequired: true },
        { ...LeasingField.organizationName, isHidden: true },
        { ...LeasingField.organizationInn, isHidden: true },
        {
          ...LeasingField.policyAgreement,
          text: messages.leasingOffer.label.agreement.alfaLeasing.link,
        },
      ],
      submitButton: baseLeasingOfferStep.submitButton,
    },
  ],
  [EFinancingOfferType.LEASING_INTERLEASING]: [
    {
      ...baseLeasingOfferStep,
      fields: [
        { ...LeasingField.fullName, isHidden: true },
        LeasingField.phone,
        LeasingField.email,
        LeasingField.comment,
        { ...LeasingField.organizationName, isHidden: true },
        { ...LeasingField.organizationInn, isHidden: true },
        {
          ...LeasingField.policyAgreement,
        },
      ],
      submitButton: baseLeasingOfferStep.submitButton,
    },
  ],
  [EFinancingOfferType.LEASING_VTB]: [
    {
      ...baseLeasingOfferStep,
      fields: [
        { ...LeasingField.fullName, isHidden: true },
        LeasingField.phone,
        LeasingField.email,
        { ...LeasingField.organizationName, isHidden: true },
        {
          ...LeasingField.policyAgreement,
        },
      ],
      submitButton: {
        ...baseLeasingOfferStep.submitButton,
        onClick: () => pushFormattedGtmEvent(VTBLeasingClickGtmEventPayload.leasingApplicationForm),
      },
    },
  ],
  [EFinancingOfferType.LEASING_RAL]: [
    {
      ...baseLeasingOfferStep,
      fields: [
        { ...LeasingField.fullName, isHidden: true },
        LeasingField.phone,
        LeasingField.email,
        { ...LeasingField.organizationInn, isHidden: true },
        { ...LeasingField.comment, isRequired: true, minLength: 3 },
        {
          ...LeasingField.policyAgreement,
        },
      ],
      submitButton: {
        ...baseLeasingOfferStep.submitButton,
        onClick: () => pushFormattedGtmEvent(submitButtonEventPayload),
      },
    },
  ],
  [EFinancingOfferType.SBER_LEASING]: [
    {
      ...baseLeasingOfferStep,
      fields: [
        { ...LeasingField.fullName, isHidden: true },
        LeasingField.phone,
        LeasingField.email,
        { ...LeasingField.organizationInn, isHidden: true },
        { ...LeasingField.organizationName, isHidden: true },
        { ...LeasingField.comment, isRequired: true, minLength: 3 },
        {
          ...LeasingField.policyAgreement,
        },
      ],
      submitButton: {
        ...baseLeasingOfferStep.submitButton,
      },
    },
  ],
  [EFinancingOfferType.LEASING_STONE_XXI]: [
    {
      ...baseLeasingOfferStep,
      fields: [
        { ...LeasingField.fullName, isHidden: true },
        LeasingField.phone,
        LeasingField.email,
        { ...LeasingField.comment, isRequired: true },
        { ...LeasingField.organizationName, isHidden: true },
        { ...LeasingField.organizationInn, isHidden: true },
        {
          ...LeasingField.policyAgreement,
        },
      ],
      submitButton: {
        ...baseLeasingOfferStep.submitButton,
        onClick: () => pushFormattedGtmEvent(submitButtonEventPayload),
      },
    },
  ],
  [EFinancingOfferType.CREDIT]: [
    {
      ...creditOfferSteps,
      errorMessageId: messages.creditOfferModal.error,
      titleId: messages.creditOfferModal.title,
    },
  ],
  [EFinancingOfferType.REVOLVING_LOAN_SBER]: [
    {
      titleId: messages.creditOfferModal.title,
      fields: [
        { ...LeasingField.organizationName, isHidden: true },
        { ...LeasingField.organizationInn, isHidden: true },
        {
          fieldType: 'regionDropdown',
          isRequired: true,
          isSearchable: true,
          modifiers: ['light-grey'],
          directoryRegions: EDirectoryRegions.SBER,
          name: 'regionCode',
        },
        {
          name: 'phone',
          labelId: messages.creditOfferModal.label.phone,
          type: 'tel',
          isRequired: true,
          isDisabled: true,
          inputMode: 'numeric',
        },
        {
          name: 'email',
          type: 'email',
          labelId: messages.creditOfferModal.label.email,
          isRequired: true,
        },
        {
          ...LOAN_PURPOSE_FIELD,
        },
        {
          name: 'loanAmount.value',
          minValue: 1,
          minValueErrorMessageId: 'incorrectMinValue',
          labelId: messages.loanMFO.label.loanAmount,
          type: 'price',
          isRequired: true,
          inputMode: 'numeric',
        },
        {
          ...LOAN_AGREEMENT,
          text: messages.revolvingLoanSber.field.agreement.label,
        },
      ],
      submitButton: {
        ...creditOfferSteps.submitButton,
        textId: messages.creditOfferModal.submit,
      },
      errorMessageId: messages.creditOfferModal.error,
    },
  ],
  [EFinancingOfferType.ADVANCE_FUNDING]: [
    {
      fields: [
        {
          name: 'fullName',
          labelId: messages.advancePaymentOffer.label.fullName,
          isRequired: true,
          isDisabled: true,
          isHidden: true,
        },
        {
          name: 'phone',
          fieldType: 'text',
          type: 'tel',
          inputMode: 'numeric',
          isRequired: true,
          isDisabled: true,
          label: messages.advancePaymentOffer.label.phone,
        },
        {
          name: 'email',
          type: 'email',
          labelId: messages.advancePaymentOffer.label.email,
          isRequired: true,
          isDisabled: true,
          isHidden: true,
        },
        {
          name: 'organizationInn',
          labelId: messages.advancePaymentOffer.label.organizationInn,
          isRequired: true,
          isDisabled: true,
          isHidden: true,
        },
        {
          name: 'organizationName',
          labelId: messages.advancePaymentOffer.label.organizationName,
          isRequired: true,
          isDisabled: true,
          isHidden: true,
        },
        {
          name: 'regionCode',
          fieldType: 'regionDropdown',
          isRequired: true,
          isSearchable: true,
          modifiers: ['light-grey'],
        },
        {
          name: 'loanAmount.value',
          minValue: 1,
          minValueErrorMessageId: 'incorrectMinValue',
          type: 'price',
          fieldType: 'text',
          labelId: messages.advancePaymentOffer.label.loanAmount,
          isRequired: true,
          hideCurrency: true,
          inputMode: 'numeric',
        },
        {
          ...LOAN_AGREEMENT,
          text: messages.advancePaymentOffer.field.agreement.label,
        },
      ],
      submitButton: {
        textId: messages.advancePaymentOffer.button.submit,
        modifiers: ['green'],
        className: classNames('financing-offer-form-container__advance-button_submit'),
        animatedContainerClass: 'financing-offer-form-button-animated-container financing-offer-form-button-animated-container_submit',
      },
      titleId: messages.advancePaymentOffer.title,
      errorMessageId: messages.advancePaymentOffer.error,
      shouldShowRequiringFieldsLabel: false,
    },
  ],
  [EFinancingOfferType.INDIVIDUAL_OFFER]: [
    {
      ...creditOfferSteps,
      titleId: messages.individualOffer.title,
      errorMessageId: messages.individualOffer.error,
    },
  ],
  [EFinancingOfferType.CREDIT_TINKOFF]: [
    {
      titleId: messages.creditOfferModal.title,
      fields: [
        TinkoffField.fullName,
        TinkoffField.phone,
        TinkoffField.organizationInn,
        TinkoffField.organizationName,
        TinkoffField.loanAmount,
        TinkoffField.policyAgreement,
      ],
      submitButton: {
        ...creditOfferSteps.submitButton,
        onClick: () => pushFormattedGtmEvent(submitTinkoffCreditFormEventPayload),
        textId: messages.creditOfferModal.submit,
      },
    },
  ],
  [EFinancingOfferType.ALFA_EXPRESS_LOAN]: [
    {
      titleId: messages.creditOfferModal.title,
      fields: [
        {
          fieldType: 'searchCitiesWithSuggestions',
          apiType: 'alfa',
          valueFormattingVariant: 'code',
          isRequired: true,
        },
        {
          name: 'phone',
          labelId: messages.tinkoffOffer.label.phone,
          type: 'tel',
          isRequired: true,
          isDisabled: true,
          inputMode: 'numeric',
        },
        {
          name: 'organizationInn',
          isHidden: true,
        },
        {
          name: 'organizationName',
          isHidden: true,
        },
        {
          name: 'email',
          type: 'email',
          labelId: messages.creditOfferModal.label.email,
        },
        {
          name: 'comment',
          labelId: messages.expressCreditAlfaBank.label.comment,
          type: 'textArea',
        },
        {
          name: 'loanAmount.value',
          minValue: 1,
          minValueErrorMessageId: 'incorrectMinValue',
          labelId: messages.expressCreditAlfaBank.label.loanAmount,
          type: 'price',
          isRequired: true,
          inputMode: 'numeric',
          customValidator: hasMaxValue(10_000_000, messages.expressCreditAlfaBank.error.incorrectMaxLoanValue),
        } as ITextInputProps,
        {
          ...LOAN_AGREEMENT,
          text: messages.creditOffer.label.alfaBank.link,
        },
      ],
      submitButton: {
        ...creditOfferSteps.submitButton,
        textId: messages.creditOfferModal.submit,
      },
    },
  ],
  [EFinancingOfferType.EXPRESS_MFO]: [
    {
      titleId: messages.creditOfferModal.title,
      fields: [
        {
          name: 'phone',
          labelId: messages.loanMFO.label.phone,
          type: 'tel',
          inputMode: 'numeric',
          isRequired: true,
          isDisabled: true,
        },
        {
          fieldType: 'regionDropdown',
          customValidator: validateAvailableRegionsForFinancingProduct(messages.common.forms.incorrectRegionsForFinancingProduct, [
            EFinancingProductId.EXPRESS_MFO,
          ]),
          isRequired: true,
          isSearchable: true,
          modifiers: ['light-grey'],
        },
        {
          name: 'organizationInn',
          isHidden: true,
        },
        {
          name: 'organizationName',
          isHidden: true,
        },
        {
          name: 'loanAmount.value',
          minValue: 1,
          minValueErrorMessageId: 'incorrectMinValue',
          labelId: messages.loanMFO.label.loanAmount,
          type: 'price',
          hideCurrency: true,
          isRequired: true,
          inputMode: 'numeric',
          customValidator: hasMaxValue(200_000_000, messages.expressMFO.error.incorrectMaxLoanValue),
        } as ITextInputProps,
        {
          ...LOAN_AGREEMENT,
          text: messages.expressMFO.field.agreement.label,
          linkToAgreement: {
            ...LOAN_AGREEMENT.linkToAgreement,
            url: createRouterLink(ELegalDocumentsRoute.DOCUMENT_VERSION, {
              documentId: 'USER_REGISTRATION_RULES',
              versionId: 'USER_REGISTRATION_RULES',
            }),
          },
        },
      ],
      submitButton: {
        ...creditOfferSteps.submitButton,
        textId: messages.expressMFO.submitButton,
        modifiers: ['green'],
      },

      errorMessageId: messages.individualOffer.error,
    },
  ],
  [EFinancingOfferType.BNPL_POLECREDIT]: [
    {
      titleId: messages.creditOfferModal.title,
      description: messages.creditOfferModal.description,
      fields: [
        {
          name: 'phone',
          labelId: messages.loanMFO.label.phone,
          type: 'tel',
          inputMode: 'numeric',
          isRequired: true,
          isDisabled: true,
        },
        {
          fieldType: 'regionDropdown',
          customValidator: validateAvailableRegionsForFinancingProduct(messages.common.forms.incorrectRegionsForFinancingProduct, [
            EFinancingProductId.BNPL_POLECREDIT,
          ]),
          isRequired: true,
          isSearchable: true,
          modifiers: ['light-grey'],
        },
        {
          name: 'organizationInn',
          isHidden: true,
        },
        {
          name: 'organizationName',
          isHidden: true,
        },
        {
          name: 'loanAmount.value',
          minValue: 1,
          minValueErrorMessageId: 'incorrectMinValue',
          labelId: messages.loanMFO.label.loanAmount,
          type: 'price',
          hideCurrency: true,
          isRequired: true,
          inputMode: 'numeric',
          customValidator: hasMaxValue(200_000_000, 'incorrectMaxLoanValue25mln'),
        } as ITextInputProps,
        {
          ...LOAN_AGREEMENT,
          text: messages.expressMFO.field.agreement.label,
          linkToAgreement: {
            ...LOAN_AGREEMENT.linkToAgreement,
            url: createRouterLink(ELegalDocumentsRoute.DOCUMENT_VERSION, {
              documentId: 'USER_REGISTRATION_RULES',
              versionId: 'USER_REGISTRATION_RULES',
            }),
          },
        },
      ],
      submitButton: {
        ...creditOfferSteps.submitButton,
        textId: messages.expressMFO.submitButton,
        modifiers: ['green'],
      },

      errorMessageId: messages.individualOffer.error,
    },
  ],
  [EFinancingOfferType.SBER_INVESTMENT_LOAN]: [
    {
      titleId: messages.creditOfferModal.title,
      fields: [
        {
          fieldType: 'regionDropdown',
          isRequired: true,
          isSearchable: true,
          modifiers: ['light-grey'],
          directoryRegions: EDirectoryRegions.SBER,
          name: 'regionCode',
        },
        {
          name: 'phone',
          labelId: messages.loanMFO.label.phone,
          type: 'tel',
          isRequired: true,
          isDisabled: true,
          inputMode: 'numeric',
        },
        {
          name: 'organizationInn',
          isHidden: true,
        },
        {
          name: 'organizationName',
          isHidden: true,
        },
        {
          name: 'email',
          type: 'email',
          labelId: messages.creditOfferModal.label.email,
          isRequired: true,
        },
        {
          ...LOAN_PURPOSE_FIELD,
        },
        {
          name: 'loanAmount.value',
          minValue: 1,
          minValueErrorMessageId: 'incorrectMinValue',
          labelId: messages.loanMFO.label.loanAmount,
          type: 'price',
          isRequired: true,
          inputMode: 'numeric',
        } as ITextInputProps,
        {
          ...LOAN_AGREEMENT,
          text: messages.sberInvestmentLoan.field.agreement.label,
        },
      ],
      submitButton: {
        ...creditOfferSteps.submitButton,
      },
      errorMessageId: messages.individualOffer.error,
    },
  ],
  [EFinancingOfferType.LOMBARD_LOAN_ALFA_BANK]: [
    {
      titleId: messages.creditOfferModal.title,
      fields: [
        {
          name: 'city',
          labelId: messages.form.placeholder.nearestCity,
          isRequired: true,
        },
        {
          name: 'phone',
          labelId: messages.tinkoffOffer.label.phone,
          type: 'tel',
          isRequired: true,
          isDisabled: true,
          inputMode: 'numeric',
        },
        {
          name: 'organizationInn',
          isHidden: true,
        },
        {
          name: 'organizationName',
          isHidden: true,
        },
        {
          name: 'comment',
          labelId: messages.form.placeholder.comment,
        },
        {
          name: 'loanAmount.value',
          minValue: 1,
          minValueErrorMessageId: 'incorrectMinValue',
          labelId: messages.expressCreditAlfaBank.label.loanAmount,
          type: 'price',
          inputMode: 'numeric',
          isRequired: true,
          customValidator: hasMaxValue(50_000_000, messages.lombardLoanAlfaBank.error.incorrectMaxLoanValue),
        } as ITextInputProps,
        {
          ...LOAN_AGREEMENT,
          text: messages.creditOffer.label.alfaBank.link,
        },
      ],
      submitButton: {
        ...creditOfferSteps.submitButton,
        textId: messages.creditOfferModal.submit,
      },
    },
  ],
  [EFinancingOfferType.AGENT]: [
    {
      titleId: messages.creditOfferModal.title,
      fields: [
        {
          name: 'fullName',
          labelId: messages.agent.label.fullName,
          isRequired: true,
        },
        {
          name: 'phone',
          labelId: messages.agent.label.phone,
          type: 'tel',
          isRequired: true,
          isDisabled: true,
          inputMode: 'numeric',
        },
        {
          fieldType: 'regionMultiDropdown',
          isRequired: true,
          isSearchable: true,
          modifiers: ['light-grey'],
          name: 'regionCode',
        },
      ],
      text: messages.centerInvestLoan.field.agreement.label,
      submitButton: {
        textId: messages.creditOfferModal.submitButton,
        className: 'financing-offer-form-container__advance-button',
        animatedContainerClass: 'financing-offer-form-button-animated-container',
        modifiers: ['green'],
        onClick: () => pushFormattedGtmEvent(submitButtonEventPayload),
      },
      shouldShowRequiringFieldsLabel: true,
      shouldDisableButton: false,
      errorMessageId: messages.individualOffer.error,
    },
  ],
  [EFinancingOfferType.CENTER_INVEST_LOAN]: [
    {
      titleId: messages.creditOfferModal.title,
      fields: [
        {
          name: 'city',
          fieldType: 'searchCitiesWithSuggestions',
          labelId: messages.form.placeholder.nearestCity,
          isRequired: true,
          valueFormattingVariant: 'nameAndRegionKladrId',
          apiType: 'dadata',
          customValidator: validateAvailableRegionsForFinancingProduct(
            messages.common.forms.incorrectRegionsForFinancingProduct,
            [EFinancingProductId.CENTER_INVEST_LOAN],
            EMethodValidateRegion.ISO_CODE,
          ),
        },
        {
          name: 'phone',
          labelId: messages.loanMFO.label.phone,
          type: 'tel',
          isRequired: true,
          isDisabled: true,
          inputMode: 'numeric',
        },
        {
          name: 'organizationInn',
          isHidden: true,
        },
        {
          name: 'organizationName',
          isHidden: true,
        },
        {
          name: 'loanAmount.value',
          minValue: 1,
          minValueErrorMessageId: 'incorrectMinValue',
          label: messages.centerInvestLoan.field.loanAmount.label,
          type: 'price',
          isRequired: true,
          inputMode: 'numeric',
        } as ITextInputProps,
        {
          name: 'loanTerm',
          label: messages.centerInvestLoan.field.loanTerm.label,
          type: 'num',
          inputMode: 'numeric',
          isRequired: true,
          minValue: 1,
          children: messages.centerInvestLoan.field.loanTerm.suffix,
        } as ITextInputProps,
        {
          ...LOAN_PURPOSE_FIELD,
        },
        {
          ...LOAN_AGREEMENT,
          text: messages.centerInvestLoan.field.agreement.label,
        },
      ],
      submitButton: {
        ...creditOfferSteps.submitButton,
      },
      errorMessageId: messages.individualOffer.error,
    },
  ],
  [EFinancingOfferType.LEASING_FLEET_FINANCE]: [
    {
      ...baseLeasingOfferStep,
      fields: [
        { ...LeasingField.fullName, isHidden: true },
        LeasingField.phone,
        LeasingField.email,
        { ...LeasingField.organizationInn, isHidden: true },
        { ...LeasingField.organizationName, isHidden: true },
        { ...LeasingField.comment, isRequired: true, minLength: 3 },
        {
          ...LeasingField.policyAgreement,
        },
      ],
      submitButton: {
        ...baseLeasingOfferStep.submitButton,
        onClick: () => pushFormattedGtmEvent(submitButtonEventPayload),
      },
    },
  ],
}

type TFormValuesFormatter = (formValues: IFinancingOfferFormValues) => IApplyingFinancingOfferRequestBody

export const FormValuesFormatter: Partial<Record<EFinancingOfferType, TFormValuesFormatter>> = {
  [EFinancingOfferType.LEASING_ALFA]: formValues => {
    const body: IApplyingFinancingOfferRequestBody = omit(formValues, ['city'])
    const city = formValues.city

    if (isCitySuggestionFormDadata(city)) {
      body.fiasCode = city.fiasCode
      body.city = `${city.city}, ${city.region}`
    }

    return body
  },
  [EFinancingOfferType.EXPRESS_MFO]: formValues => {
    const body: IApplyingFinancingOfferRequestBody = omit(formValues, ['city', ...LOAN_PURPOSE_FIELD_NAMES])
    const city = formValues.city

    if (isCitySuggestionFormDadata(city)) {
      body.regionCode = Number(city.regionKladrId.slice(0, 2))
      body.city = `${city.city}, ${city.region}`
    }

    body.comment = getHumanReadablePurposes(formValues)

    return body
  },
  [EFinancingOfferType.AGENT]: formValues => {
    const body: IApplyingFinancingOfferRequestBody = omit(formValues, ['city', ...LOAN_PURPOSE_FIELD_NAMES])
    const city = formValues.city

    if (isCitySuggestionFormDadata(city)) {
      body.regionCode = Number(city.regionKladrId.slice(0, 2))
      body.city = `${city.city}, ${city.region}`
    }

    body.comment = getHumanReadablePurposes(formValues)

    return body
  },
  [EFinancingOfferType.CENTER_INVEST_LOAN]: formValues => {
    const body: IApplyingFinancingOfferRequestBody = omit(formValues, ['city', 'loanTerm', ...LOAN_PURPOSE_FIELD_NAMES])
    const city = formValues.city

    if (isCitySuggestionFormDadata(city)) {
      body.regionCode = Number(city.regionKladrId.slice(0, 2))
      body.city = `${city.city}, ${city.region}`
    }

    const commentFieldPartsSeparator = '; '

    const humanReadableNumberOfMonths = formatLocalizedString(messages.centerInvestLoan.field.loanTerm.humanReadableValue, {
      numberOfMonths: formValues.loanTerm,
    })
    const humanReadableLoanPurpose = getHumanReadablePurposes(formValues)

    body.comment = [humanReadableNumberOfMonths, humanReadableLoanPurpose].join(commentFieldPartsSeparator)

    return body
  },
  [EFinancingOfferType.REVOLVING_LOAN_SBER]: formValues => {
    const body: IApplyingFinancingOfferRequestBody = omit(formValues, ['city', ...LOAN_PURPOSE_FIELD_NAMES])

    body.comment = getHumanReadablePurposes(formValues)

    return body
  },
  [EFinancingOfferType.SBER_INVESTMENT_LOAN]: formValues => {
    const body: IApplyingFinancingOfferRequestBody = omit(formValues, ['city', ...LOAN_PURPOSE_FIELD_NAMES])

    body.comment = getHumanReadablePurposes(formValues)

    return body
  },
}

export const financingOfferFailStatuses = [
  ERegistrationRequestStatus.LOAN_AMOUNT_IS_NOT_IN_INTERVAL,
  ERegistrationRequestStatus.OKVEDS_NOT_FOUND,
  ERegistrationRequestStatus.STATUS_NOT_ACTIVE,
]

export const IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN = true

const LEASING_COMPANY_LOGOS_TO_HIDE: Readonly<string[]> = IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN ? ['stone-xxi', 'alfa'] : []

export const LEASING_COMPANY_LOGOS: Readonly<string[]> = ['rosagro', 'stone-xxi', 'interleasing', 'alfa', 'fleet-finance', 'sber'].filter(
  logo => !LEASING_COMPANY_LOGOS_TO_HIDE.includes(logo),
)

export const AGROTECHNICS_FINANCING_PRODUCTS_TO_HIDE: Readonly<string[]> = IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN
  ? [EFinancingProductId.LEASING_STONE_XXI, EFinancingProductId.LEASING_ALFA]
  : []

export const AGROTECHNICS_FINANCING_PRODUCTS: Readonly<EFinancingProductId[]> = [
  EFinancingProductId.SBER_LEASING,
  EFinancingProductId.LEASING_STONE_XXI,
  EFinancingProductId.LEASING_INTERLEASING,
  EFinancingProductId.LEASING_ALFA,
  EFinancingProductId.LEASING_RAL,
  EFinancingProductId.LEASING_FLEET_FINANCE,
].filter(id => !AGROTECHNICS_FINANCING_PRODUCTS_TO_HIDE.includes(id))

export const USED_AGROTECHNICS_FINANCING_PRODUCTS: Readonly<EFinancingProductId[]> = [
  EFinancingProductId.LEASING_STONE_XXI,
  EFinancingProductId.SBER_LEASING,
  EFinancingProductId.LEASING_FLEET_FINANCE,
].filter(id => !AGROTECHNICS_FINANCING_PRODUCTS_TO_HIDE.includes(id))

export const FORM_ANCHOR = '#form'

export const financingProductsNames: Partial<Record<EFinancingProductId, string>> = {
  [EFinancingProductId.SBER_LEASING]: 'Лизинг «Сбер Лизинг»',
  [EFinancingProductId.LEASING_STONE_XXI]: 'Лизинг «СТОУН-XXI»',
  [EFinancingProductId.LEASING_INTERLEASING]: 'Лизинг «Интерлизинг»',
  [EFinancingProductId.LEASING_ALFA]: 'Альфа-Лизинг',
  [EFinancingProductId.LEASING_RAL]: 'Лизинг «Росагролизинг»',
  [EFinancingProductId.LEASING_FLEET_FINANCE]: 'Лизинг «Fleet Finance»',
  [EFinancingProductId.REVOLVING_LOAN_SBER]: 'Оборотный кредит Сбербанк',
  [EFinancingProductId.EXPRESS_MFO]: 'Простые займы для аграриев – MONEY_KAPITAL AGROINTEGRATOR',
  [EFinancingProductId.AGENT]: 'Зарабатывайте вместе с ПолеФинанс',
  [EFinancingProductId.CENTER_INVEST_LOAN]: 'Кредиты для фермеров на любые бизнес цели Банк Центр-Инвест',
  [EFinancingProductId.SBER_INVESTMENT_LOAN]: 'Инвестиционный кредит Сбербанк',
  [EFinancingProductId.ADVANCE_FUNDING]: 'Авансирование под залог будущего урожая',
  [EFinancingProductId.MONEY_KAPITAL]: 'Простые займы для аграриев – MONEY_KAPITAL AGROINTEGRATOR',
  [EFinancingProductId.MULTI_LEASING]: 'Мульти-лизинг',
  [EFinancingProductId.MARKETPLACE]: 'Заявка на консультацию',
}

export const FINANCING_PURPOSE_OPTIONS = [
  { value: 'Покупка товаров / ТМЦ', label: 'Покупка товаров / ТМЦ' },
  { value: 'Покупка техники', label: 'Покупка техники' },
  { value: 'Оборотные средства', label: 'Оборотные средства' },
  { value: 'Капитальные затраты', label: 'Капитальные затраты' },
  { value: 'Развитие бизнеса', label: 'Развитие бизнеса' },
  { value: 'Рефинансирование', label: 'Рефинансирование' },
] satisfies IDropdownOption[]
