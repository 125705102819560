import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetItemsQueryResponse, GetItemsQueryParams } from "../types/GetItems";

export const getItemsQueryKey = (params: GetItemsQueryParams) =>     [{url:`/api/purchase-tenders/v1/items`},...(params ? [params] : [])] as const;
      export function getItemsQueryOptions <TData = GetItemsQueryResponse, TError = unknown>(params: GetItemsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getItemsQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/purchase-tenders/v1/items`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/purchase-tenders/v1/items
 */

export function useGetItems <TData = GetItemsQueryResponse, TError = unknown>(params: GetItemsQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getItemsQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getItemsQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
