
export enum ValueFieldType {
    "INPUT" = "INPUT",
    "SELECT" = "SELECT",
    "MONEY" = "MONEY",
    "DATE_RANGE" = "DATE_RANGE"
}
export type ValueField = {
    /**
     * @type integer int32
    */
    order: number;
    /**
     * @type string
    */
    type: ValueFieldType;
};
