import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { UnpublishAuctionsMutationRequest, UnpublishAuctionsMutationResponse } from "../../types/UnpublishAuctions";

/**
     * @description Метод предназначен для переноса предложений в черновики (аукциона) https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425486308
    Аукционы меняют статус c ACTIVE на PLANNED
    
     * @link /v1/auctions/do_unpublish
     */
export async function unpublishAuctions (data: UnpublishAuctionsMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UnpublishAuctionsMutationResponse>["data"]> {
    const { data: resData } = await client<UnpublishAuctionsMutationResponse, UnpublishAuctionsMutationRequest>({
        method: "post",
        url: `/v1/auctions/do_unpublish`,
        data,
        ...options
    });
    
    return resData;
};