import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteParticipantMutationResponse, DeleteParticipantQueryParams } from "../../types/DeleteParticipant";

/**
     * @link /api/purchase-tenders/v1/participant
     */
export async function deleteParticipant (params: DeleteParticipantQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteParticipantMutationResponse>["data"]> {
    const { data: resData } = await client<DeleteParticipantMutationResponse>({
        method: "delete",
        url: `/api/purchase-tenders/v1/participant`,
        params,
        ...options
    });
    
    return resData;
};