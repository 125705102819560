import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'

interface IUseIsLoadingDebouncedProps {
  delay: number
}

export function useIsLoadingDebounced({
  delay,
}: IUseIsLoadingDebouncedProps): [isLoadingDebounced: boolean, setIsLoading: Dispatch<SetStateAction<boolean>>, isLoading: boolean] {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDebounced, setIsLoadingDebounced] = useState(false)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (isLoading) {
      timer = setTimeout(() => setIsLoadingDebounced(isLoading), delay)
    } else {
      setIsLoadingDebounced(false)
    }

    return () => clearInterval(timer)
  }, [delay, isLoading])

  return [isLoadingDebounced, setIsLoading, isLoading]
}
