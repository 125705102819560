import type { TMessages } from '@/types/TMessages'

export const messages = {
  header: {
    title: '<Highlighted>Авансирование</Highlighted>\nпод будущий урожай',
    description: 'Получите деньги под урожай без ежемесячных платежей за 7 рабочих дней',
    additionalButton: 'Об авансировании',
  },

  details: {
    title: 'Быстрое получение <Highlighted>денежных средств</Highlighted> на любые цели',
    list: {
      sum: {
        label: 'Сумма аванса',
        value: '250 млн руб',
      },
      crop: {
        label: 'Культура',
        value: 'Пшеница',
      },
      repaymentProcedure: {
        label: 'Порядок погашения',
        value: 'без ежемесячных платежей',
      },
      deadline: {
        label: 'Предоставление денег',
        value: '7 раб. дней',
      },
    },
  },

  steps: {
    title: '<Highlighted>Этапы</Highlighted> получения средств',
    '1': {
      title: 'Предварительный расчёт и консультация',
    },
    '2': {
      title: 'Сбор документов и удалённый анализ',
      description: 'до 5 дней после получения документов',
    },
    '3': {
      title: 'Выезд агроконсультанта на сельхозпредприятие',
      description: 'до 2 дней после обработки документов',
    },
    '4': {
      title: 'Утверждение условий сделки и подписание договора',
    },
    '5': {
      title: 'Получение денежных средств',
      description: 'до 3 дней с момента получения документов',
    },
    button: 'Подать заявку',
  },

  benefits: {
    title: 'Авансирование урожая от <Highlighted>«Деметра Трейдинг»</Highlighted> — это:',
    items: {
      decisionMaking: 'Принятие решения и заключение сделки в кратчайшие сроки',
      simpleProcedure: 'Простая процедура и минимальный пакет документов',
      individualApproach: 'Индивидуальный подход и адаптация услуги под условия производства',
      reliableLongTermPartnership: 'Надёжное долгосрочное партнерство',
    },
  },

  loanTerms: {
    title: '<Highlighted>Условия</Highlighted> авансирования',
    termGroups: {
      mainParameters: {
        title: 'Основные параметры',
        columnHeader: {
          byLand: 'Под залог земли',
          standardProcedure: 'Стандарт процедура',
          withBankGuarantee: 'Банковская гарантия',
        },
        rows: {
          refusalOfDelivery: {
            title: 'Отказ от поставки / Поставка в неполном объеме',
            clause: {
              common: {
                description: 'В случае срыва поставки (поставки не в полном объеме) поставщик обязуется:',
                obligations: {
                  1: 'вернуть часть аванса, не закрытую совершенными поставками, с начислением процентов по ставке ЦБ РФ в трехкратном размерес даты получения аванса;',
                  2: 'уплатить неустойку за непоставленный товар, в размере пропорционально непоставленному объему.',
                },
              },
            },
          },
          limit: {
            title: 'Лимит',
            clause: {
              byLand: 'до 250 млн. руб.',
              withBankGuarantee: 'до 2 млрд. руб.',
              standardProcedure: 'до 2 млрд. руб.',
            },
          },
          collateralOptions: {
            title: 'Варианты обеспечения',
            clause: {
              byLand: 'залог земли сельскохозяйственного назначения в собственности, либо в аренде от государства сроком от 25 лет',
              withBankGuarantee: 'банковская гарантия',
              standardProcedure: 'залог продукции будущего урожая в объеме поставки',
            },
          },
          interest: {
            title: 'Ставка (уменьшает цену за тонну)',
            clause: {
              byLand: 'КС + 5-6%',
              withBankGuarantee: 'КС + 5% + от 3% за БГ',
              standardProcedure: 'КС + 6%',
            },
          },
          additional: {
            title: 'Доп. инфо',
            clause: {
              byLand: 'дополнительный расход на пошлину при регистрации (по 175 руб. с каждой стороны сделки)',
              withBankGuarantee: 'комиссия банку за выпуск гарантии ≈ 3%-4,5%',
              standardProcedure: 'требуются дополнительные расходы на агроконсультанта, который будет сопровождать пашню',
            },
          },
        },
      },
      additionalParameters: {
        title: 'Дополнительные параметры',
        rows: {
          forWhom: {
            title: 'Для кого',
            clause: {
              common: {
                description: 'Для ИП, (Г)КФХ, КФХ и ЮЛ',
              },
            },
          },
          expenditure: {
            title: 'Статьи расходов',
            clause: {
              common: {
                checks: {
                  1: 'любые виды закупок для нужд сельхозтоваропроизводителя;',
                  2: 'обслуживание и модернизация помещений и парка техники;',
                  3: 'любые статьи, связанные с крупными расходами на ведение бизнеса.',
                },
              },
            },
          },
          repaymentSchedule: {
            title: 'График погашения',
            clause: {
              common: {
                checks: {
                  1: 'без ежемесячных платежей;',
                  2: 'погашение поставкой урожая.',
                },
              },
            },
          },
          regionalCoverage: {
            title: 'Региональное покрытие',
            clause: {
              common: {
                description: 'Европейская часть РФ:\nЮФО, СКФО, ПФО, ЦФО',
              },
            },
          },
          deliveryInterval: {
            title: 'Интервал поставки',
            clause: {
              common: {
                description: 'По мере уборки урожая (с начала июля по конец октября – в зависимости от региона)',
              },
            },
          },
          maximumTerm: {
            title: 'Максимальный срок',
            clause: {
              common: {
                description: 'до 12 месяцев',
              },
            },
          },
          pricingOption: {
            title: 'Вариант ценообразования',
            clause: {
              common: {
                description:
                  'цена фиксируется в момент начала поставки товара по результатам товарных аукционов АО "Национальная Товарная Биржа" (см. <LinkToExchange>https://www.namex.org/ru/commodityauctions/realtimeCA</LinkToExchange>), на дату передачи товара, далее из цены вычитается стоимость ж/д доставки до порта Новороссийска, стоимость услуг элеватора, и умножается на дисконт',
              },
            },
          },
          advanceAmount: {
            title: 'Сумма аванса',
            clause: {
              common: {
                description: 'До 70% от стоимости контрактуемого товара',
              },
            },
          },
        },
      },
    },
  },

  faq: {
    title: 'Часто задаваемые <Highlighted>вопросы</Highlighted>',
    questions: {
      requiredDocuments: {
        title: 'Какие документы необходимы для получения авансирования?',
        answer: `
            <Title>Ознакомьтесь со списком необходимых документов:</Title>
            <ul>
              <li><LinkToQuestionnaire>Анкета контрагента</LinkToQuestionnaire></li>
              <li><LinkToLetter>Письмо об ответственности общее</LinkToLetter></li>
              <li><LinkToApplication>Приложение 1_Справка по текущим обязательствам клиента</LinkToApplication></li>
              <li><LinkToDocuments>Список документов от СХТП - аванс</LinkToDocuments></li>
            </ul>
        `,
      },
      propertyCollateral: {
        title: 'Требуется ли залог имущества для получения авансирования?',
        answer: '<p>Залог имущества, как правило, не требуется. Возможен как опция по суммам аванса от 100 млн рублей.</p>',
      },
      advancePaymentStages: {
        title: 'Можно ли выдачу аванса разделить в несколько этапов?',
        answer: '<p>Да, можем организовать выдачу аванса траншами в рамках согласованного лимита авансирования, в разные даты.</p>',
      },
      premiumForHigherProteinLevel: {
        title: 'Если пшеница будет с показателями протеина выше, чем в договоре, будет ли надбавка?',
        answer:
          '<p>В дополнительном соглашении к договору купли-продажи может быть предусмотрена надбавка/скидка к цене товара для случаев, когда фактическое содержание протеина или иного параметра поставленного товара отличается от базисного значения.</p>',
      },
      deliveryToPort: {
        title: 'Вы сами осуществляете доставку до порта?',
        answer: `
          <p>В рамках данного продукты мы используем Базисы поставки: EXW;CPT;FCA; в случае, если СХТП находится в ЮФО, то мы можем осуществить доставку «со склада». В иных случаях, Вы можете воспользоваться услугами элеватора, а мы в свою очередь организуем ЖД логистику.</p>
          <p>Список уже аккредитованных элеваторов <LinkToPortList>здесь.</LinkToPortList></p>
        `,
      },
      deliveryLocation: {
        title: 'Вы можете осуществить поставки из любой точки России?',
        answer: '<p>Да, мы работаем по всей территории РФ.</p>',
      },
    },
  },
  form: {
    title: 'Подайте заявку на авансирование!',
    description:
      'После отправки заявки в течение часа вам перезвонит финансовый менеджер <Highlighted>поле.рф</Highlighted> для уточнения запроса',
  },
  breadcrumbs: {
    financing: 'Финансирование',
    currentPage: 'Авансирование под будущий урожай',
  },
} satisfies TMessages
