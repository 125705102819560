import React from 'react'
import { messages } from '../messages'

export function CantSignMessageError(cantSignMessage: string = '') {
  return (
    <div>
      <p>{messages.organizationDetailsRequired.description}</p>
      <p className="space-holder-top8">{cantSignMessage}</p>
    </div>
  )
}
