import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { FindPublicPurchaseTendersByParamsQueryResponse, FindPublicPurchaseTendersByParamsQueryParams } from "../../types/FindPublicPurchaseTendersByParams";

/**
     * @link /api/purchase-tenders/pub/v1/tenders
     */
export async function findPublicPurchaseTendersByParams (params?: FindPublicPurchaseTendersByParamsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindPublicPurchaseTendersByParamsQueryResponse>["data"]> {
    const { data: resData } = await client<FindPublicPurchaseTendersByParamsQueryResponse>({
        method: "get",
        url: `/api/purchase-tenders/pub/v1/tenders`,
        params,
        ...options
    });
    
    return resData;
};