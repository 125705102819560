import type { ResponseValueFieldsResponseDto } from "./ResponseValueFieldsResponseDto";

export enum GetValueFieldsQueryParamsPurchaseTenderScreenEnum {
    "ADD_ITEMS" = "ADD_ITEMS",
    "SUBMIT_BID" = "SUBMIT_BID"
}
export type GetValueFieldsQueryParams = {
    /**
     * @type integer int64
    */
    tenderId: number;
    /**
     * @type string
    */
    purchaseTenderScreenEnum: GetValueFieldsQueryParamsPurchaseTenderScreenEnum;
};

/**
 * @description OK
*/
export type GetValueFieldsQueryResponse = ResponseValueFieldsResponseDto;
