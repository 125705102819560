import type { DateRangeField } from "./DateRangeField";
import type { InputField } from "./InputField";
import type { MoneyField } from "./MoneyField";
import type { SelectField } from "./SelectField";

export enum FieldsResponsePositionType {
    "SPECIFIC_PRODUCT" = "SPECIFIC_PRODUCT",
    "SELECTION_BY_CHARACTERISTICS" = "SELECTION_BY_CHARACTERISTICS"
}
export enum FieldsResponseFieldsMode {
    "ANALOGUE" = "ANALOGUE",
    "ORIGINAL" = "ORIGINAL"
}
export type FieldsResponse = {
    /**
     * @type array
    */
    fields: (DateRangeField | InputField | MoneyField | SelectField)[][];
    /**
     * @type string
    */
    positionType: FieldsResponsePositionType;
    /**
     * @type string | undefined
    */
    fieldsMode?: FieldsResponseFieldsMode;
    /**
     * @type integer int32
    */
    vatRate: number;
    /**
     * @type number
    */
    price: number;
};
