import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateDocumentPublicMutationRequest, CreateDocumentPublicMutationResponse, CreateDocumentPublicQueryParams } from "../../types/CreateDocumentPublic";

/**
     * @link /api/purchase-tenders/pub/v1/save_document
     */
export async function createDocumentPublic (params: CreateDocumentPublicQueryParams, data?: CreateDocumentPublicMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateDocumentPublicMutationResponse>["data"]> {
    const { data: resData } = await client<CreateDocumentPublicMutationResponse, CreateDocumentPublicMutationRequest>({
        method: "post",
        url: `/api/purchase-tenders/pub/v1/save_document`,
        params,
        data,
        ...options
    });
    
    return resData;
};