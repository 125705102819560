import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { DownloadReportBetsQueryResponse, DownloadReportBetsQueryParams } from "../../types/DownloadReportBets";

/**
     * @description Метод предназначен для запроса отчета (формирование отчета),  содержащего информацию о ставках, по одному аукциону https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425486284
    
     * @link /v1/auction/download_report-bets
     */
export async function downloadReportBets (params: DownloadReportBetsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DownloadReportBetsQueryResponse>["data"]> {
    const { data: resData } = await client<DownloadReportBetsQueryResponse>({
        method: "get",
        url: `/v1/auction/download_report-bets`,
        params,
        ...options
    });
    
    return resData;
};