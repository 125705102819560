import type { PurchaseTenderItemPositionOfferResponse } from "./PurchaseTenderItemPositionOfferResponse";

export enum PurchaseTenderComparativeRowTenderItemPositionType {
    "SPECIFIC_PRODUCT" = "SPECIFIC_PRODUCT",
    "SELECTION_BY_CHARACTERISTICS" = "SELECTION_BY_CHARACTERISTICS"
}
export type PurchaseTenderComparativeRowTenderItem = {
    /**
     * @type integer int64
    */
    itemId: number;
    item: PurchaseTenderItemPositionOfferResponse;
    /**
     * @type string
    */
    positionType: PurchaseTenderComparativeRowTenderItemPositionType;
};
