export enum ECommonRoute {
  main = '/',
  error500 = '/error-500',
  error404 = '/error-404',
  impersonation = '/impersonation',
  temporaryBasicAuthPage = '/start',
  feedback = '/feedback',
  businessProcess = '/business-process',
  sberAuthRedirect = '/sber-auth-redirect',
  qrCode = '/qr-oplata',
  payOnline = '/pay-online',
  tendersPublicList = '/tenders',
  tenderPublic = '/tenders/:tenderUid',
  tenderPublicComparativeTable = '/tenders/:tenderId/comparative-table',
  tenderPublicOfferForm = '/tenders/:tenderUid/make-offer',
  mobileRedirect = '/mobile-redirect',
  aboutUs = '/about-us',
}
