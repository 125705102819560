import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { ChangeAutoImproveAuctionMutationRequest, ChangeAutoImproveAuctionMutationResponse, ChangeAutoImproveAuctionQueryParams, ChangeAutoImproveAuction400, ChangeAutoImproveAuction404 } from "../types/ChangeAutoImproveAuction";

/**
 * @description Метод предназначен для включения/отключения автоизменения цены предложений (аукциона) https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425486326

 * @link /v1/auction/do_change-auto-improve
 */

export function useChangeAutoImproveAuction <TData = ChangeAutoImproveAuctionMutationResponse, TError = ChangeAutoImproveAuction400 | ChangeAutoImproveAuction404, TVariables = ChangeAutoImproveAuctionMutationRequest>(params: ChangeAutoImproveAuctionQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/v1/auction/do_change-auto-improve`,
        data,
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
