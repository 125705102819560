import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { PublishAuctionsMutationRequest, PublishAuctionsMutationResponse, PublishAuctions400, PublishAuctions401, PublishAuctions403, PublishAuctions500 } from "../types/PublishAuctions";

/**
 * @description Метод предназначен для побликации предложения (аукциона) https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425486304
Аукционы меняют статус с DRAFT на PLANNED.

 * @link /v1/auctions/do_publish
 */

export function usePublishAuctions <TData = PublishAuctionsMutationResponse, TError = PublishAuctions400 | PublishAuctions401 | PublishAuctions403 | PublishAuctions500, TVariables = PublishAuctionsMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/v1/auctions/do_publish`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
