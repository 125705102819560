import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteTenderBidDocumentMutationResponse, DeleteTenderBidDocumentQueryParams } from "../../types/DeleteTenderBidDocument";

/**
     * @link /api/purchase-tenders/v1/my-file
     */
export async function deleteTenderBidDocument (params: DeleteTenderBidDocumentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteTenderBidDocumentMutationResponse>["data"]> {
    const { data: resData } = await client<DeleteTenderBidDocumentMutationResponse>({
        method: "delete",
        url: `/api/purchase-tenders/v1/my-file`,
        params,
        ...options
    });
    
    return resData;
};