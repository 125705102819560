import type { ResponseValueFieldsResponseDto } from "./ResponseValueFieldsResponseDto";

export enum GetValueFieldsPublicQueryParamsPurchaseTenderScreenEnum {
    "ADD_ITEMS" = "ADD_ITEMS",
    "SUBMIT_BID" = "SUBMIT_BID"
}
export type GetValueFieldsPublicQueryParams = {
    /**
     * @type string uuid
    */
    tenderId: string;
    /**
     * @type string
    */
    purchaseTenderScreenEnum: GetValueFieldsPublicQueryParamsPurchaseTenderScreenEnum;
};

/**
 * @description OK
*/
export type GetValueFieldsPublicQueryResponse = ResponseValueFieldsResponseDto;
