import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateItemMutationRequest, CreateItemMutationResponse, CreateItemQueryParams } from "../../types/CreateItem";

/**
     * @link /api/purchase-tenders/v1/items
     */
export async function createItem (data: CreateItemMutationRequest, params: CreateItemQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateItemMutationResponse>["data"]> {
    const { data: resData } = await client<CreateItemMutationResponse, CreateItemMutationRequest>({
        method: "post",
        url: `/api/purchase-tenders/v1/items`,
        params,
        data,
        ...options
    });
    
    return resData;
};