import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetPublicTenderItemsQueryResponse, GetPublicTenderItemsQueryParams } from "../types/GetPublicTenderItems";

export const getPublicTenderItemsQueryKey = (params: GetPublicTenderItemsQueryParams) =>     [{url:`/api/purchase-tenders/pub/v1/public-tender-items`},...(params ? [params] : [])] as const;
      export function getPublicTenderItemsQueryOptions <TData = GetPublicTenderItemsQueryResponse, TError = unknown>(params: GetPublicTenderItemsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getPublicTenderItemsQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/purchase-tenders/pub/v1/public-tender-items`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/purchase-tenders/pub/v1/public-tender-items
 */

export function useGetPublicTenderItems <TData = GetPublicTenderItemsQueryResponse, TError = unknown>(params: GetPublicTenderItemsQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getPublicTenderItemsQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getPublicTenderItemsQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
