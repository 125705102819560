import type { EProfileRequestType } from '@/logic/profile/constants'
import type { EAccreditationType } from '@/types/Accreditation'
import type { EOrderPaymentVariantType } from '@/types/Cart'
import type { IHarvestSaleRequestStatus } from '@/types/HarvestSaleRequest'
import type { IOrderStatus } from '@/types/Order'
import type { TEntityId } from '@/types/TEntityId'

export interface IStatusModelActionsProps {
  allowToEditOrder: () => void
  shouldEditOrder: boolean
  openRejectingOrderModal: () => void
  payOrderWithSberBusiness?: () => void
  openFeedback?: () => void
  openSupplies?: () => void
  isOrderClosed?: boolean
  changeOrderStatus: () => void
  openSupplyFormModal?: () => void
  isOrderStatusChangingInProcess: boolean
  status?: IOrderStatus | IHarvestSaleRequestStatus
  entityType: EProfileRequestType
  id: TEntityId
  version: string
  organizationId?: string
  isConfirmationButtonDisabled?: boolean
  accreditationType: EAccreditationType
  paymentType?: EOrderPaymentVariantType
}

export enum EOrganizationDetailsStatus {
  ORGANIZATION_DETAILS_REQUIRED = 'ORGANIZATION_DETAILS_REQUIRED',
}
