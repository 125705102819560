import { useEffect, useState } from 'react'
import { getApprovedRegions } from '@/api/kubik/credit'
import type { IApplyingFinancingOfferProps } from '@/logic/financing/interfaces'
import { EFinancingProductId } from '@/types'

export const useRegionsMicrocreditOrganizations = (props: IApplyingFinancingOfferProps) => {
  const { productId } = props

  const [regionsAvailableExpressMFO, setRegionsAvailableExpressMFO] = useState<number[]>([])
  const isMFOProduct = productId === EFinancingProductId.EXPRESS_MFO

  useEffect(() => {
    if (isMFOProduct) {
      getApprovedRegions({ productId: EFinancingProductId.EXPRESS_MFO }).then(regions => {
        setRegionsAvailableExpressMFO(regions?.approvedRegions || [])
      })
    }
  }, [isMFOProduct])

  return { regionsAvailableExpressMFO, isMFOProduct }
}
