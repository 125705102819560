import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { FundingAgentDoCalculateMutationRequest, FundingAgentDoCalculateMutationResponse, FundingAgentDoCalculate400, FundingAgentDoCalculate401, FundingAgentDoCalculate403, FundingAgentDoCalculate422, FundingAgentDoCalculate429, FundingAgentDoCalculate500 } from "../types/FundingAgentDoCalculate";

/**
 * @link /api/credit-flow-backend/private/v1/calculation/do_calculate-funding-agent-reward
 */

export function useFundingAgentDoCalculate <TData = FundingAgentDoCalculateMutationResponse, TError = FundingAgentDoCalculate400 | FundingAgentDoCalculate401 | FundingAgentDoCalculate403 | FundingAgentDoCalculate422 | FundingAgentDoCalculate429 | FundingAgentDoCalculate500, TVariables = FundingAgentDoCalculateMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/credit-flow-backend/private/v1/calculation/do_calculate-funding-agent-reward`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
