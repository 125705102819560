
export enum ValidationType {
    "MIN" = "MIN",
    "MAX" = "MAX",
    "REQUIRED" = "REQUIRED"
}
export type Validation = {
    /**
     * @type string
    */
    type: ValidationType;
    /**
     * @type string
    */
    value: string;
};
