import { paramsArraySerializer } from '@/api/client/axiosParamsSerializer'
import { findPublicPurchaseTendersByParams, findPublicPurchaseTendersByParamsQueryKey } from '@/api/kubik/tender'
import type { TSsrPrefetchQueries } from '@/interfaces'
import { mapTendersPayloadToFetchRequestParams } from '@/pages/TendersPublicList/utils/mappers/mapTendersPayloadToFetchRequestParams'

type PageParams = 'categoryId' | 'ownerOrganizations' | 'federalDistricts' | 'regionCodes' | 'page'

export const ssrPrefetchQueriesForTenders: TSsrPrefetchQueries<PageParams> = (routeParams, query, additionalParams) => {
  const categoryId = routeParams.categoryId
  const ownerOrganizations = routeParams.ownerOrganizations
  const federalDistricts = routeParams.federalDistricts
  const regionCodes = routeParams.regionCodes
  const page = routeParams.page

  const newParams = mapTendersPayloadToFetchRequestParams({
    params: { categoryId, ownerOrganizations, federalDistricts, regionCodes, page },
    search: query,
  })

  const queryKey = findPublicPurchaseTendersByParamsQueryKey(newParams)

  return [
    {
      queryFn: () =>
        findPublicPurchaseTendersByParams(newParams, {
          ...(additionalParams?.token && { headers: { Authorization: `Bearer ${additionalParams?.token}` } }),
          paramsSerializer: paramsArraySerializer,
        }),
      queryKey,
    },
  ]
}
