import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteAllParticipantsMutationResponse, DeleteAllParticipantsQueryParams } from "../types/DeleteAllParticipants";

/**
 * @link /api/purchase-tenders/v1/participants
 */

export function useDeleteAllParticipants <TData = DeleteAllParticipantsMutationResponse, TError = unknown>(params: DeleteAllParticipantsQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "delete",
        url: `/api/purchase-tenders/v1/participants`,
        
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
