import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetPublicPurchaseTenderQueryResponse, GetPublicPurchaseTenderQueryParams } from "../types/GetPublicPurchaseTender";

export const getPublicPurchaseTenderQueryKey = (params: GetPublicPurchaseTenderQueryParams) =>     [{url:`/api/purchase-tenders/pub/v1/tender`},...(params ? [params] : [])] as const;
      export function getPublicPurchaseTenderQueryOptions <TData = GetPublicPurchaseTenderQueryResponse, TError = unknown>(params: GetPublicPurchaseTenderQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getPublicPurchaseTenderQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/purchase-tenders/pub/v1/tender`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/purchase-tenders/pub/v1/tender
 */

export function useGetPublicPurchaseTender <TData = GetPublicPurchaseTenderQueryResponse, TError = unknown>(params: GetPublicPurchaseTenderQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getPublicPurchaseTenderQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getPublicPurchaseTenderQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
