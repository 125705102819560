import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { ChangePurchaseTenderCategoryMutationRequest, ChangePurchaseTenderCategoryMutationResponse, ChangePurchaseTenderCategoryQueryParams } from "../types/ChangePurchaseTenderCategory";

/**
 * @link /api/purchase-tenders/v1/change_tender_category
 */

export function useChangePurchaseTenderCategory <TData = ChangePurchaseTenderCategoryMutationResponse, TError = unknown, TVariables = ChangePurchaseTenderCategoryMutationRequest>(params: ChangePurchaseTenderCategoryQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "patch",
        url: `/api/purchase-tenders/v1/change_tender_category`,
        data,
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
