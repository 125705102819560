import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetCompetitiveListQueryResponse, GetCompetitiveListQueryParams } from "../../types/GetCompetitiveList";

/**
     * @link /api/purchase-tenders/v1/competitive-list
     */
export async function getCompetitiveList (params: GetCompetitiveListQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetCompetitiveListQueryResponse>["data"]> {
    const { data: resData } = await client<GetCompetitiveListQueryResponse>({
        method: "get",
        url: `/api/purchase-tenders/v1/competitive-list`,
        params,
        ...options
    });
    
    return resData;
};