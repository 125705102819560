import type { FindPublicPurchaseTendersByParamsQueryParams } from '@/api/kubik/tender'
import { getPagination } from '@/pages/AgriculturalProductCatalog/utils'
import { DEFAULT_OFFSET } from '@/pages/TendersPublicList/components/TendersPublicListForm/constants'

function parseValue(value: string) {
  return value ? (JSON.parse(decodeURIComponent(value)) ?? null) : {}
}

// @ts-ignore todo: WIP
export const mapTendersPayloadToFetchRequestParams = ({ params, search }) => {
  const payload = { ...params, ...search }
  const perPage = DEFAULT_OFFSET
  const newPayload: FindPublicPurchaseTendersByParamsQueryParams = {}
  const { categoryId, ownerOrganizations, federalDistricts, regionCodes, page } = params

  newPayload.categoryId = categoryId
  newPayload.ownerOrganizations = parseValue(ownerOrganizations)
  newPayload.federalDistricts = parseValue(federalDistricts)
  newPayload.regionCodes = parseValue(regionCodes)
  const paginationData = getPagination(Number(page || '1'), { perPage })
  Object.assign(newPayload, paginationData)

  return payload
}
