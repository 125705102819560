import type { TenderWinner } from "./TenderWinner";
import type { ActionDto } from "./ActionDto";

export enum PurchaseTenderResponseType {
    "PRIVATE" = "PRIVATE",
    "PUBLIC" = "PUBLIC"
}
export enum PurchaseTenderResponseMethod {
    "REQUEST_FOR_PROPOSALS" = "REQUEST_FOR_PROPOSALS"
}
export enum PurchaseTenderResponseStatus {
    "DRAFT" = "DRAFT",
    "ACCEPTANCE_PROPOSALS" = "ACCEPTANCE_PROPOSALS",
    "REVIEW_PROPOSALS" = "REVIEW_PROPOSALS",
    "COMPLETED" = "COMPLETED"
}
export enum PurchaseTenderResponseProcessingStatus {
    "ITEMS_FILE_ERROR" = "ITEMS_FILE_ERROR",
    "ITEMS_FILE_EMPTY_ERROR" = "ITEMS_FILE_EMPTY_ERROR",
    "ITEMS_FILE_SUCCESS" = "ITEMS_FILE_SUCCESS",
    "ITEMS_FILE_IN_PROGRESS" = "ITEMS_FILE_IN_PROGRESS"
}
export enum PurchaseTenderResponseSubStatus {
    "I_AM_WINNER" = "I_AM_WINNER",
    "WINNER_SELECTED" = "WINNER_SELECTED",
    "NO_WINNER_SELECTED" = "NO_WINNER_SELECTED",
    "NO_BIDS" = "NO_BIDS",
    "HAS_BIDS" = "HAS_BIDS"
}
export type PurchaseTenderResponse = {
    /**
     * @type integer int64
    */
    id: number;
    /**
     * @type string uuid
    */
    uid: string;
    /**
     * @type string
    */
    type: PurchaseTenderResponseType;
    /**
     * @type string
    */
    method: PurchaseTenderResponseMethod;
    /**
     * @type string date-time
    */
    startDateProposals: string;
    /**
     * @type string
    */
    status: PurchaseTenderResponseStatus;
    /**
     * @type string | undefined
    */
    processingStatus?: PurchaseTenderResponseProcessingStatus;
    /**
     * @type string | undefined
    */
    subStatus?: PurchaseTenderResponseSubStatus;
    /**
     * @type string | undefined date
    */
    endDateProposals?: string;
    /**
     * @type array | undefined
    */
    winners?: TenderWinner[];
    /**
     * @type array | undefined
    */
    actions?: ActionDto[];
    /**
     * @type boolean | undefined
    */
    isPartitionTender?: boolean;
    /**
     * @type integer int32
    */
    valRate: number;
};
