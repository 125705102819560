import type { MonetaryAmountDto } from "./MonetaryAmountDto";

export enum PurchaseTenderDtoType {
    "PRIVATE" = "PRIVATE",
    "PUBLIC" = "PUBLIC"
}
export enum PurchaseTenderDtoMethod {
    "REQUEST_FOR_PROPOSALS" = "REQUEST_FOR_PROPOSALS"
}
export enum PurchaseTenderDtoStatus {
    "DRAFT" = "DRAFT",
    "ACCEPTANCE_PROPOSALS" = "ACCEPTANCE_PROPOSALS",
    "REVIEW_PROPOSALS" = "REVIEW_PROPOSALS",
    "COMPLETED" = "COMPLETED"
}
export enum PurchaseTenderDtoProcessingStatus {
    "ITEMS_FILE_ERROR" = "ITEMS_FILE_ERROR",
    "ITEMS_FILE_EMPTY_ERROR" = "ITEMS_FILE_EMPTY_ERROR",
    "ITEMS_FILE_SUCCESS" = "ITEMS_FILE_SUCCESS",
    "ITEMS_FILE_IN_PROGRESS" = "ITEMS_FILE_IN_PROGRESS"
}
export type PurchaseTenderDto = {
    /**
     * @type integer int64
    */
    id: number;
    /**
     * @type string
    */
    name: string;
    /**
     * @type string
    */
    type: PurchaseTenderDtoType;
    /**
     * @type string
    */
    method: PurchaseTenderDtoMethod;
    /**
     * @type string date-time
    */
    startDateProposals: string;
    /**
     * @type string date
    */
    endDateProposals: string;
    /**
     * @type string
    */
    status: PurchaseTenderDtoStatus;
    /**
     * @type string | undefined
    */
    processingStatus?: PurchaseTenderDtoProcessingStatus;
    cost?: MonetaryAmountDto;
    /**
     * @type boolean
    */
    isPartitionTender: boolean;
    /**
     * @type string | undefined uuid
    */
    UID?: string;
    /**
     * @type string uuid
    */
    uid: string;
};
