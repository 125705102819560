
export enum ActionDtoType {
    "SELECT_WINNER" = "SELECT_WINNER",
    "MAKE_BID" = "MAKE_BID",
    "CANCEL_BID" = "CANCEL_BID",
    "EDIT_BID" = "EDIT_BID",
    "EDIT_TENDER" = "EDIT_TENDER",
    "REJECT_ALL_BIDS" = "REJECT_ALL_BIDS",
    "VIEW_COMPARATIVE_TABLE" = "VIEW_COMPARATIVE_TABLE",
    "VIEW_PROPOSALS" = "VIEW_PROPOSALS",
    "VIEW_POSITIONS" = "VIEW_POSITIONS"
}
export type ActionDto = {
    /**
     * @type string
    */
    type: ActionDtoType;
};
