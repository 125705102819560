import breadcrumbsMessages from '@/components/ui/Breadcrumbs/messages.json'
import type { IFinancingProductInfo } from '@/logic/metrika/financing/types'
import { messages as advancePaymentOnFutureHarvestPageMessages } from '@/pages/AdvancePaymentOnFutureHarvest/messages'
import { messages as expressCreditAlfaBankMessages } from '@/pages/ExpressCreditAlfaBank/messages'
import { getEventContext } from '@/pages/Financing/gtmHelpers'
import { messages as interleasingPageMessages } from '@/pages/Interleasing/messages'
import type { RootState } from '@/redux/interfaces'
import type { ESubmitFormStatusWithGtm } from '@/types'
import { EFinancingOfferRoute, EFinancingOfferType, EFinancingProductId } from '@/types'
import { messages as financingMessages } from './financing/messages'
import { getUserGtmInfo, pushFormattedGtmEvent } from './helpers'
import type { IFormattedGtmEventPayload } from './interfaces'

export enum EIntersectionState {
  intersected = 'intersected',
  didNotIntersect = 'didNotIntersect',
}

export const basePageViewingGtmEvent: Omit<IFormattedGtmEventPayload, 'eventContext'> = {
  event: 'userEvent',
  pagePart: 'content',
  blockName: null,
  eventCategory: 'interactions',
  eventAction: 'viewing',
  eventLabel: 'pageView',
  eventStatus: 'success',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}

export const baseScrollGtmEvent: Omit<IFormattedGtmEventPayload, 'eventLabel'> = {
  event: 'userEvent',
  pagePart: 'content',
  eventCategory: 'interactions',
  eventAction: 'scrolling',
  eventContext: null,
  eventStatus: 'success',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
  blockName: null,
}

export const baseFocusGtmEvent: Omit<IFormattedGtmEventPayload, 'blockName' | 'eventLabel'> = {
  event: 'userEvent',
  pagePart: 'content',
  eventCategory: 'interactions',
  eventAction: 'click',
  eventContext: null,
  eventStatus: 'success',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}

export const baseChangeGtmEvent: Omit<IFormattedGtmEventPayload, 'blockName' | 'eventLabel'> = {
  event: 'userEvent',
  pagePart: 'content',
  eventCategory: 'interactions',
  eventAction: 'input',
  eventContext: null,
  eventStatus: 'success',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}

export const baseClickGtmEvent: Omit<IFormattedGtmEventPayload, 'blockName' | 'eventLabel'> = {
  event: 'userEvent',
  pagePart: 'content',
  eventCategory: 'interactions',
  eventAction: 'click',
  eventContext: null,
  eventStatus: 'success',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}

export const baseFormSubmitGtmEvent: Omit<IFormattedGtmEventPayload, 'blockName' | 'eventStatus'> = {
  event: 'userEvent',
  pagePart: 'content',
  eventCategory: 'conversions',
  eventAction: 'backgroundProcess',
  eventLabel: 'processFormSent',
  eventContext: null,
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}

export const financingProductGtmInfo: Partial<Record<EFinancingProductId, IFinancingProductInfo>> = {
  [EFinancingOfferType.ALFA_EXPRESS_LOAN]: {
    product: expressCreditAlfaBankMessages.header.title,
    productNameOnLending: expressCreditAlfaBankMessages.header.title,
    bank: 'Альфа банк',
  },
  [EFinancingProductId.TINKOFF_REVOLVING_LOAN]: {
    product: breadcrumbsMessages.ru['breadcrumbs.financingOffer.express'],
    productNameOnLending: breadcrumbsMessages.ru['breadcrumbs.financingOffer.replenishmentRevolving'],
    bank: 'Тинькофф банк',
  },
  [EFinancingProductId.EXPRESS_LOAN]: {
    product: financingMessages.product.expressLoan,
    productNameOnLending: breadcrumbsMessages.ru['breadcrumbs.financingOffer.express'],
    bank: 'ВТБ',
  },
  [EFinancingOfferType.REVOLVING_LOAN]: {
    product: financingMessages.product.revolvingLoan,
    productNameOnLending: breadcrumbsMessages.ru['breadcrumbs.financingOffer.revolving'],
    bank: 'ВТБ',
  },
  [EFinancingProductId.ADVANCE_FUNDING]: {
    product: breadcrumbsMessages.ru['breadcrumbs.financingOffer.advancePaymentOnFutureHarvest'],
    productNameOnLending: advancePaymentOnFutureHarvestPageMessages.header.title,
    bank: 'Деметра холдинг',
  },
  [EFinancingProductId.LEASING_INTERLEASING]: {
    product: interleasingPageMessages.header.title,
    productNameOnLending: interleasingPageMessages.header.title,
    bank: 'Интерлизинг',
  },
  [EFinancingProductId.LEASING_VTB]: {
    product: financingMessages.product.vtbLeasing,
    productNameOnLending: breadcrumbsMessages.ru['breadcrumbs.financingOffer.transportAndSpecialEquipmentLeasing'],
    bank: 'ВТБ Лизинг',
  },
  [EFinancingProductId.LEASING_RAL]: {
    product: financingMessages.product.transportAndSpecialEquipmentLeasing,
    productNameOnLending: interleasingPageMessages.header.title,
    bank: 'Росагролизинг',
  },
  [EFinancingProductId.INVESTMENT]: {
    product: financingMessages.product.investmentLoan,
    productNameOnLending: breadcrumbsMessages.ru['breadcrumbs.financingOffer.investment'],
    bank: 'ВТБ',
  },
  [EFinancingProductId.LEASING_ALFA]: {
    product: financingMessages.product.alfaLeasing,
    bank: 'Альфа лизинг',
  },
}

export const financingSection: Partial<Record<EFinancingProductId, string>> = {
  [EFinancingProductId.ALFA_EXPRESS_LOAN]: financingMessages.offerGroup.express.title,
  [EFinancingProductId.TINKOFF_REVOLVING_LOAN]: financingMessages.offerGroup.express.title,
  [EFinancingProductId.REVOLVING_LOAN]: financingMessages.offerGroup.replenish.title,
  [EFinancingProductId.ADVANCE_FUNDING]: financingMessages.offerGroup.replenish.title,
  [EFinancingProductId.LEASING_ALFA]: financingMessages.offerGroup.leasing.title,
  [EFinancingProductId.LEASING_INTERLEASING]: financingMessages.offerGroup.leasing.title,
  [EFinancingProductId.LEASING_VTB]: financingMessages.offerGroup.leasing.title,
  [EFinancingProductId.LEASING_RAL]: financingMessages.offerGroup.leasing.title,
  [EFinancingProductId.INVESTMENT]: financingMessages.offerGroup.capital.title,
}

export const financingProduct: Partial<Record<EFinancingOfferRoute, IFinancingProductInfo>> = {
  [EFinancingOfferRoute.expressCreditAlfaBank]: financingProductGtmInfo[EFinancingProductId.ALFA_EXPRESS_LOAN],
  [EFinancingOfferRoute.revolvingTinkoff]: financingProductGtmInfo[EFinancingProductId.TINKOFF_REVOLVING_LOAN],
  [EFinancingOfferRoute.expressCredit]: financingProductGtmInfo[EFinancingProductId.EXPRESS_LOAN],
  [EFinancingOfferRoute.revolving]: financingProductGtmInfo[EFinancingProductId.REVOLVING_LOAN],
  [EFinancingOfferRoute.oborotSber]: financingProductGtmInfo[EFinancingProductId.REVOLVING_LOAN_SBER],
  [EFinancingOfferRoute.advancePaymentOnFutureHarvest]: financingProductGtmInfo[EFinancingProductId.ADVANCE_FUNDING],
  [EFinancingOfferRoute.FinancingAgentPage]: financingProductGtmInfo[EFinancingProductId.AGENT],
  [EFinancingOfferRoute.interleasing]: financingProductGtmInfo[EFinancingProductId.LEASING_INTERLEASING],
  [EFinancingOfferRoute.transportAndSpecialEquipmentLeasing]: financingProductGtmInfo[EFinancingProductId.LEASING_VTB],
  [EFinancingOfferRoute.agriculturalMachinesAndEquipmentLeasing]: financingProductGtmInfo[EFinancingProductId.LEASING_RAL],
  [EFinancingOfferRoute.investmentCredit]: financingProductGtmInfo[EFinancingProductId.INVESTMENT],
  [EFinancingOfferRoute.stoneXxiLeasing]: financingProductGtmInfo[EFinancingProductId.LEASING_STONE_XXI],
  [EFinancingOfferRoute.fleetFinanceLeasing]: financingProductGtmInfo[EFinancingProductId.LEASING_FLEET_FINANCE],
}

export const baseSubmitFormEventPayload: Omit<IFormattedGtmEventPayload, 'blockName' | 'eventStatus' | 'eventLabel' | 'eventContext'> = {
  event: 'userEvent',
  pagePart: 'content',
  eventCategory: 'conversions',
  eventAction: 'backgroundProcess',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
  userSegment: null,
}

export const submitButtonEventPayload: IFormattedGtmEventPayload = {
  event: 'userEvent',
  pagePart: 'content',
  blockName: 'blockLoanApplicationForm',
  eventCategory: 'interactions',
  eventAction: 'click',
  eventLabel: 'buttonSendRequest',
  eventContext: null,
  eventStatus: 'success',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}

export const submitTinkoffCreditFormEventPayload: IFormattedGtmEventPayload = {
  ...baseFormSubmitGtmEvent,
  pagePart: 'content',
  eventCategory: 'interactions',
  eventAction: 'click',
  blockName: 'blockLoanApplicationForm',
  eventLabel: 'buttonSendRequest',
  eventStatus: 'success',
}

export enum EScrollEventBlockId {
  bannerPrepayment = 'bannerPrepayment',
  expressLoan = 'expressLoan',
  workingLoan = 'workingLoan',
  advancePaymentOnBail = 'advancePaymentOnBail',
  agriculturalLeasing = 'agriculturalLeasing',
  automobileLeasing = 'automobileLeasing',
  investmentLoan = 'investmentLoan',
  individualConditionsVTB = 'individualConditionsVTB',
  lendingTerms = 'lendingTerms',
  investSber = 'investSber',
}

export enum EClickEventBlockId {
  blockStagesOfReceivingFunds = 'blockStagesOfReceivingFunds',
  blockPersonalData = 'blockPersonalData',
}
export enum ETinkoffFormGtmFieldName {
  agreement = 'agreement',
}

export const baseGtmScrollEvent: Omit<IFormattedGtmEventPayload, 'eventLabel'> = {
  event: 'userEvent',
  pagePart: 'content',
  blockName: null,
  eventCategory: 'interactions',
  eventAction: 'scrolling',
  eventContext: null,
  eventStatus: 'success',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}

export const GtmScrollEventPayload: Partial<Record<EScrollEventBlockId, IFormattedGtmEventPayload>> = {
  [EScrollEventBlockId.bannerPrepayment]: {
    ...baseGtmScrollEvent,
    eventLabel: 'scrollBannerPrepayment',
  },
  [EScrollEventBlockId.expressLoan]: {
    ...baseGtmScrollEvent,
    eventLabel: 'scrollExpressLoan',
  },
  [EScrollEventBlockId.workingLoan]: {
    ...baseGtmScrollEvent,
    eventLabel: 'scrollWorkingLoan',
  },
  [EScrollEventBlockId.advancePaymentOnBail]: {
    ...baseGtmScrollEvent,
    eventLabel: 'scrollAdvancePaymentOnBail',
  },
  [EScrollEventBlockId.agriculturalLeasing]: {
    ...baseGtmScrollEvent,
    eventLabel: 'scrollAgriculturalLeasing',
  },
  [EScrollEventBlockId.automobileLeasing]: {
    ...baseGtmScrollEvent,
    eventLabel: 'scrollAutomobileLeasing',
  },
  [EScrollEventBlockId.investmentLoan]: {
    ...baseGtmScrollEvent,
    eventLabel: 'scrollInvestmentLoan',
  },
  [EScrollEventBlockId.individualConditionsVTB]: {
    ...baseGtmScrollEvent,
    eventLabel: 'scrollIndividualConditionsVTB',
  },
  [EScrollEventBlockId.lendingTerms]: {
    ...baseGtmScrollEvent,
    eventLabel: 'scrollLendingTerms',
  },
}

export const baseEventClick: Omit<IFormattedGtmEventPayload, 'blockName' | 'eventLabel'> = {
  event: 'userEvent',
  pagePart: 'content',
  eventCategory: 'interactions',
  eventAction: 'click',
  eventContext: null,
  eventStatus: 'success',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}

export const FinancingClickGtmEventPayload: Record<EClickEventBlockId, IFormattedGtmEventPayload> = {
  [EClickEventBlockId.blockStagesOfReceivingFunds]: {
    ...baseEventClick,
    blockName: '111',
    eventLabel: 'buttonSendRequest',
  },
  [EClickEventBlockId.blockPersonalData]: {
    ...baseEventClick,
    blockName: 'blockLoanApplicationForm',
    eventLabel: 'agreementPersonalData',
  },
}

export const TinkoffFormFocusingGtmEventPayload: Record<ETinkoffFormGtmFieldName, IFormattedGtmEventPayload> = {
  [ETinkoffFormGtmFieldName.agreement]: {
    ...baseFocusGtmEvent,
    blockName: 'blockLoanApplicationForm',
    eventLabel: 'checkboxPersonalData',
  },
}

export enum EClickVTBEventBlockId {
  transportAndSpecialEquipmentLeasing = 'transportAndSpecialEquipmentLeasing',
  selectTransport = 'selectTransport',
  leasingCalculator = 'leasingCalculator',
  stagesOfReceivingFunds = 'stagesOfReceivingFunds',
  specialOffers = 'specialOffers',
  truckLeasing = 'truckLeasing',
  leasingApplicationForm = 'leasingApplicationForm',
}

export const VTBLeasingClickGtmEventPayload: Record<EClickVTBEventBlockId, IFormattedGtmEventPayload> = {
  [EClickVTBEventBlockId.transportAndSpecialEquipmentLeasing]: {
    ...baseClickGtmEvent,
    blockName: 'blockTransportAndSpecialEquipmentLeasing',
    eventLabel: 'buttonSubmitApplication',
  },
  [EClickVTBEventBlockId.selectTransport]: {
    ...baseClickGtmEvent,
    blockName: 'blockSelectTransport',
    eventLabel: 'buttonSendRequest',
  },
  [EClickVTBEventBlockId.leasingCalculator]: {
    ...baseClickGtmEvent,
    blockName: 'blockLeasingCalculator',
    eventLabel: 'buttonSendRequest',
  },
  [EClickVTBEventBlockId.stagesOfReceivingFunds]: {
    ...baseClickGtmEvent,
    blockName: 'blockStagesOfReceivingFunds',
    eventLabel: 'buttonSendRequest',
  },
  [EClickVTBEventBlockId.specialOffers]: {
    ...baseClickGtmEvent,
    blockName: 'blockSpecialOffers',
    eventLabel: 'buttonSendRequest',
  },
  [EClickVTBEventBlockId.truckLeasing]: {
    ...baseClickGtmEvent,
    blockName: 'blockTruckLeasing',
    eventLabel: 'buttonSendRequest',
  },
  [EClickVTBEventBlockId.leasingApplicationForm]: {
    ...baseClickGtmEvent,
    blockName: 'blockLeasingApplicationForm',
    eventLabel: 'buttonSendRequest',
  },
}

export const sendSubmitFormStatusEvent = (
  productId: EFinancingProductId,
  eventStatus: ESubmitFormStatusWithGtm | string,
  state: RootState,
  pathname?: string,
) => {
  const eventPayload = {
    ...baseSubmitFormEventPayload,
    blockName: 'blockLoanApplicationForm',
    eventLabel: 'loanApplicationFormSent',
    eventContext: getEventContext(financingSection[productId], financingProductGtmInfo[productId], pathname),
    ...getUserGtmInfo()(state),
    eventStatus,
  }
  if (eventPayload) pushFormattedGtmEvent(eventPayload)
}
