import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateFundingRequestMutationRequest, UpdateFundingRequestMutationResponse, UpdateFundingRequest400, UpdateFundingRequest401, UpdateFundingRequest403, UpdateFundingRequest422, UpdateFundingRequest429, UpdateFundingRequest500 } from "../types/UpdateFundingRequest";

/**
 * @link /api/credit-flow-backend/v1/funding/edit_draft-fields
 */

export function useUpdateFundingRequest <TData = UpdateFundingRequestMutationResponse, TError = UpdateFundingRequest400 | UpdateFundingRequest401 | UpdateFundingRequest403 | UpdateFundingRequest422 | UpdateFundingRequest429 | UpdateFundingRequest500, TVariables = UpdateFundingRequestMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "put",
        url: `/api/credit-flow-backend/v1/funding/edit_draft-fields`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
