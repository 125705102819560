import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetStoresQueryResponse } from "../../types/GetStores";

/**
     * @summary Получение Складов
     * @link /api/stores
     */
export async function getStores (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetStoresQueryResponse>["data"]> {
    const { data: resData } = await client<GetStoresQueryResponse>({
        method: "get",
        url: `/api/stores`,
        ...options
    });
    
    return resData;
};