export enum EUserProfileRoute {
  main = '/profile',
  orders = '/profile/orders',
  order = '/profile/orders/:id',
  myProfile = '/profile/myProfile',
  myDocuments = '/profile/documents',
  locations = '/profile/locations',
  sales = '/profile/sales',
  purchase = '/profile/purchase',
  agrarianSale = '/profile/sales/:requestId/:saleRequestType',
  organizationData = '/profile/organizationData',
  organizationDataAdditionalInfoEdit = '/profile/organizationData/additionalInfoEdit',
  organizationDataAccountsEdit = '/profile/organizationData/bankAccountsEdit',
  myChats = '/profile/chats',
  notifications = '/profile/notifications',
  myHarvest = '/profile/harvest',
  harvestDeals = '/profile/harvest-deals',
  auctionCreate = '/profile/harvest-deals/create',
  auctionEdit = '/profile/harvest-deals/edit/:auctionId',
  details = '/profile/auction-details/:auctionId',
  clients = '/profile/clients',
  agroserviceRequests = '/profile/agroservice-requests',
  myAccreditation = '/profile/accreditation',
  uploadingPriceList = '/profile/uploadingPriceList',
  fundingRequests = '/profile/funding-requests',
  fundingRequest = '/profile/funding-requests/:id',
  transactionReports = '/profile/transaction-reports',
  purchaseRequest = '/profile/purchase-request/',
  purchaseRequestEdit = '/profile/purchase-request/:id',
  tenders = '/profile/tenders',
  tenderCreate = '/profile/tenders/create',
  tender = '/profile/tenders/:tenderId',
  tenderEdit = '/profile/tenders/:tenderId/edit',
  tenderOffer = '/profile/tenders/:tenderId/offer/:offerId',
  positionOfferForm = '/profile/tenders/:tenderId/make-offer',
  contracts = '/profile/contracts',
  contract = '/profile/contracts/:contractId',
  createContract = '/profile/contracts/create',
  partnerDocuments = '/profile/partnerDocuments',
  loyaltyProgram = '/profile/loyalty-program',
  loyalty = '/loyalty',
  fundingRequestsFromClients = '/profile/funding-requests-from-clients',
  fundingRequestFromClient = '/profile/funding-request-from-client/:id',
  fundingPartnerClients = '/profile/funding-partner-clients',
  fundingPartnerClient = '/profile/funding-partner-clients/:organizationId',
}
