import { getApprovedRegions } from '@/api/kubik/credit'
import type { CreateFundingRequestData, FundingRequestData } from '@/api/kubik/monolith'
import { requestDataWithoutMeta } from '@/api/request'
import { ECommonApiUrl } from '@/api/urls'
import type { EFinancingProductId } from '@/types'

export default class FinancingService {
  create = (body: CreateFundingRequestData, isLost = false) => {
    const fundingRequestApiUrl = isLost ? ECommonApiUrl.fundingRequestsLost : ECommonApiUrl.fundingRequests

    return requestDataWithoutMeta<FundingRequestData[]>({
      url: fundingRequestApiUrl,
      method: 'POST',
      body,
    })
  }

  // На беке типизация не совпадает с реальным ответом. Есть обертка data, meta
  getAvailableRegions = async (productId: EFinancingProductId) => getApprovedRegions({ productId })
}
