import type { MonetaryAmountDto } from "./MonetaryAmountDto";

export enum UpdatePurchaseTenderDtoType {
    "POSITIONAL" = "POSITIONAL",
    "ALL_POSITION" = "ALL_POSITION"
}
export enum UpdatePurchaseTenderDtoTenderType {
    "PRIVATE" = "PRIVATE",
    "PUBLIC" = "PUBLIC"
}
export enum UpdatePurchaseTenderDtoStatus {
    "DRAFT" = "DRAFT",
    "ACCEPTANCE_PROPOSALS" = "ACCEPTANCE_PROPOSALS",
    "REVIEW_PROPOSALS" = "REVIEW_PROPOSALS",
    "COMPLETED" = "COMPLETED"
}
export type UpdatePurchaseTenderDto = {
    /**
     * @type string
    */
    type: UpdatePurchaseTenderDtoType;
    /**
     * @type string
    */
    categoryId: string;
    /**
     * @type string
    */
    name: string;
    /**
     * @type string
    */
    description: string;
    /**
     * @type string date-time
    */
    endDateProposals: string;
    /**
     * @type string | undefined date-time
    */
    startProposals?: string;
    /**
     * @type string
    */
    storeId: string;
    /**
     * @type string | undefined
    */
    tenderType?: UpdatePurchaseTenderDtoTenderType;
    price?: MonetaryAmountDto;
    /**
     * @type string
    */
    status: UpdatePurchaseTenderDtoStatus;
    /**
     * @type array | undefined
    */
    documents?: string[];
};
