import { closeModal } from '@/components/composed/Modal/actions'
import type { TAppDispatch } from '@/redux/interfaces'
import { CLOSE_MESSAGE_CONFIRM, CLOSE_ORDERS_MESSAGE_CONFIRM } from './constants'

export const confirmOnCloseSignDocumentsWithUkep = (dispatch: TAppDispatch) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-alert
    const isCloseModal = window.confirm(CLOSE_MESSAGE_CONFIRM)
    if (isCloseModal) dispatch(closeModal())
  }
}

export const confirmOnCloseSignOffersWithUkep = (dispatch: TAppDispatch) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-alert
    const isCloseModal = window.confirm(CLOSE_ORDERS_MESSAGE_CONFIRM)
    if (isCloseModal) dispatch(closeModal())
  }
}
