import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateMyBidPublicMutationRequest, CreateMyBidPublicMutationResponse, CreateMyBidPublicQueryParams } from "../../types/CreateMyBidPublic";

/**
     * @link /api/purchase-tenders/pub/v1/create_my-bids-position-offer
     */
export async function createMyBidPublic (data: CreateMyBidPublicMutationRequest, params: CreateMyBidPublicQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateMyBidPublicMutationResponse>["data"]> {
    const { data: resData } = await client<CreateMyBidPublicMutationResponse, CreateMyBidPublicMutationRequest>({
        method: "post",
        url: `/api/purchase-tenders/pub/v1/create_my-bids-position-offer`,
        params,
        data,
        ...options
    });
    
    return resData;
};