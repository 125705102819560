import type { PropsWithChildren, ReactElement } from 'react'
import React, { Children, useEffect, useState } from 'react'
import classnames from 'classnames'
import { useMount } from '@/utils/hooks'
import { changeQueryStringForTabs } from '@/utils/url'
import { Tab } from './components'
import type { ITabContentLabelComponent, ITabContentProps, ITabsProps } from './interfaces'
import './style.scss'

const isTabContentChild = (child: unknown): child is ReactElement<PropsWithChildren<ITabContentProps>> => {
  if (!child) return false

  return 'title' in (child as ReactElement<ITabContentProps>).props
}

const Tabs: React.FC<ITabsProps> = props => {
  const {
    defaultActiveTab = 0,
    forcedActivatingTab,
    tabListClassName,
    tabItemClassName,
    tabListRef,
    onTabClick,
    overrideActiveTab,
    disableChangeTabQuery,
  } = props
  const children = Children.toArray(props.children).filter(Boolean)
  const defaultTab = children[defaultActiveTab]
  const defaultTabProps = isTabContentChild(defaultTab) ? defaultTab.props : undefined
  const [innerActiveTab, setInnerActiveTab] = useState<string>(defaultTabProps?.title ?? '')

  const [activeTab, setActiveTab] = [overrideActiveTab ?? innerActiveTab, setInnerActiveTab]

  const onClickTabItem = ({ title, tabId }: ITabContentProps) => {
    setActiveTab(title)
    onTabClick?.()
    if (tabId) changeQueryStringForTabs('tab', tabId, { shouldRemoveOldValue: true, ...(props.noScrollTop && { noScrollTop: true }) })
  }

  useMount(() => {
    if (disableChangeTabQuery) return
    if (defaultTabProps?.tabId) changeQueryStringForTabs('tab', defaultTabProps.tabId)
  })

  useEffect(() => {
    if (typeof forcedActivatingTab === 'number') {
      const tab = children[forcedActivatingTab]

      if (isTabContentChild(tab)) {
        onClickTabItem(tab.props)
      }
    }
    //eslint-disable-next-line
  }, [forcedActivatingTab])

  return (
    <>
      <div className={classnames('tab-list', tabListClassName)} ref={tabListRef}>
        <div className="hidden-from-sm tab-list-shadow tab-list-shadow_left" />
        {children.map(child => {
          if (!isTabContentChild(child)) {
            return null
          }

          const { props: tabProps } = child
          const { title, isDisabled, LabelComponent } = tabProps

          return (
            <Tab
              key={title}
              className={tabItemClassName}
              label={title}
              LabelComponent={LabelComponent as ITabContentLabelComponent}
              isActive={activeTab === title}
              isDisabled={isDisabled}
              onClick={() => {
                if (activeTab !== title) onClickTabItem(tabProps)
              }}
            />
          )
        })}
        <div className="hidden-from-sm tab-list-shadow tab-list-shadow_right" />
      </div>
      <div className="tabs">
        <div className="tabs__content">
          {children.map(child => {
            if (!isTabContentChild(child)) return null

            const { title, children: tabChildren } = child.props

            return title !== activeTab ? null : tabChildren
          })}
        </div>
      </div>
    </>
  )
}

export default Tabs
