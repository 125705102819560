import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { FindParticipantQueryResponse, FindParticipantQueryParams } from "../../types/FindParticipant";

/**
     * @link /api/purchase-tenders/v1/search_participant
     */
export async function findParticipant (params: FindParticipantQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindParticipantQueryResponse>["data"]> {
    const { data: resData } = await client<FindParticipantQueryResponse>({
        method: "get",
        url: `/api/purchase-tenders/v1/search_participant`,
        params,
        ...options
    });
    
    return resData;
};