import type { ValueField } from "./ValueField";

export enum ItemType {
    "NAME" = "NAME",
    "COMMENT" = "COMMENT",
    "DELIVERY_TIME" = "DELIVERY_TIME",
    "DELIVERY_START_DATE" = "DELIVERY_START_DATE",
    "DELIVERY_END_DATE" = "DELIVERY_END_DATE",
    "QUANTITY" = "QUANTITY",
    "UNIT" = "UNIT",
    "CROP" = "CROP",
    "REGION" = "REGION",
    "PRODUCER" = "PRODUCER",
    "UNIT_PRICE" = "UNIT_PRICE",
    "DELIVERY_PRICE" = "DELIVERY_PRICE",
    "CURRENCY" = "CURRENCY",
    "CAPACITY" = "CAPACITY",
    "UNIT_CAPACITY" = "UNIT_CAPACITY",
    "PART_TYPE" = "PART_TYPE",
    "MACHINE_MODEL" = "MACHINE_MODEL",
    "PART_NUMBER" = "PART_NUMBER",
    "ANALOG" = "ANALOG",
    "UNIT_PART" = "UNIT_PART",
    "TYPE_FL" = "TYPE_FL",
    "APPLICATION" = "APPLICATION",
    "FUEL_TYPE" = "FUEL_TYPE",
    "FUEL_GRADE" = "FUEL_GRADE",
    "VISCOSITY" = "VISCOSITY",
    "PLANTED_AREA" = "PLANTED_AREA",
    "UNIT_PLANTED_AREA" = "UNIT_PLANTED_AREA",
    "PRECOCITY" = "PRECOCITY",
    "VARIETY_OR_HYBRID" = "VARIETY_OR_HYBRID",
    "FAO" = "FAO",
    "FAO_FROM" = "FAO_FROM",
    "FAO_TO" = "FAO_TO",
    "ASSIGNMENT_CORN" = "ASSIGNMENT_CORN",
    "TYPE" = "TYPE",
    "REPRODUCTION" = "REPRODUCTION",
    "CULTIVATION_TECHNOLOGY" = "CULTIVATION_TECHNOLOGY",
    "ZARAZHA_RESISTANCE" = "ZARAZHA_RESISTANCE",
    "KIND" = "KIND",
    "COMPOSITION" = "COMPOSITION",
    "PENETRATION_METHOD" = "PENETRATION_METHOD",
    "DRUG_NAME" = "DRUG_NAME",
    "PREPARATIVE_FORM" = "PREPARATIVE_FORM",
    "ACTIVE_INGREDIENT" = "ACTIVE_INGREDIENT",
    "TREATMENT_AREA" = "TREATMENT_AREA",
    "UNIT_TREATMENT_AREA" = "UNIT_TREATMENT_AREA",
    "APPLICATION_RATE" = "APPLICATION_RATE",
    "UNIT_APPLICATION_RATE" = "UNIT_APPLICATION_RATE",
    "PEST" = "PEST",
    "UNIT_OTHER" = "UNIT_OTHER"
}
export type DateRangeField = (ValueField & {
    /**
     * @type string | undefined
    */
    label?: string | null;
    /**
     * @type string | undefined
    */
    startDateDelivery?: string | null;
    /**
     * @type string | undefined
    */
    endDateDelivery?: string | null;
    /**
     * @type string | undefined
    */
    itemType?: ItemType;
});
