import type { ItemFeatureDto } from "./ItemFeatureDto";

export enum CreateItemRequestPositionType {
    "SPECIFIC_PRODUCT" = "SPECIFIC_PRODUCT",
    "SELECTION_BY_CHARACTERISTICS" = "SELECTION_BY_CHARACTERISTICS"
}
export type CreateItemRequest = {
    /**
     * @type array
    */
    itemFeatures: ItemFeatureDto[];
    /**
     * @type string
    */
    positionType: CreateItemRequestPositionType;
};
