
export enum PurchaseItemHeaderDtoOutputType {
    "TEXT" = "TEXT",
    "NUMBER" = "NUMBER",
    "DATE" = "DATE"
}
export type PurchaseItemHeaderDto = {
    /**
     * @type string
    */
    id: string;
    /**
     * @type string
    */
    title: string;
    /**
     * @type string
    */
    outputType: PurchaseItemHeaderDtoOutputType;
};
