import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { CreateAuctionDraftsMutationRequest, CreateAuctionDraftsMutationResponse, CreateAuctionDrafts400, CreateAuctionDrafts401, CreateAuctionDrafts403, CreateAuctionDrafts500 } from "../types/CreateAuctionDrafts";

/**
 * @description Создание аукционов в статусе DRAFT https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425486297

 * @link /v1/auction/do_create-draft
 */

export function useCreateAuctionDrafts <TData = CreateAuctionDraftsMutationResponse, TError = CreateAuctionDrafts400 | CreateAuctionDrafts401 | CreateAuctionDrafts403 | CreateAuctionDrafts500, TVariables = CreateAuctionDraftsMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/v1/auction/do_create-draft`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
