import type { PurchaseTenderBidShortDto } from "./PurchaseTenderBidShortDto";
import type { PurchaseTenderItemPositionOfferResponse } from "./PurchaseTenderItemPositionOfferResponse";

export enum PurchaseBidPurchaseRowItemFieldsMode {
    "ANALOGUE" = "ANALOGUE",
    "ORIGINAL" = "ORIGINAL"
}
export type PurchaseBidPurchaseRowItem = {
    /**
     * @type string | undefined
    */
    organizationId?: string;
    /**
     * @type integer int64
    */
    itemId: number;
    bid: PurchaseTenderBidShortDto;
    item: PurchaseTenderItemPositionOfferResponse;
    /**
     * @type string | undefined
    */
    email?: string;
    /**
     * @type string | undefined
    */
    inn?: string;
    /**
     * @type string | undefined
    */
    fieldsMode?: PurchaseBidPurchaseRowItemFieldsMode;
};
