import type { MonetaryAmountDto } from "./MonetaryAmountDto";
import type { PurchaseTenderComparativeRow } from "./PurchaseTenderComparativeRow";
import type { PurchaseParticipantDto } from "./PurchaseParticipantDto";
import type { PurchaseItemHeaderDto } from "./PurchaseItemHeaderDto";

export enum ComparativeTableV2DtoStatus {
    "DRAFT" = "DRAFT",
    "ACCEPTANCE_PROPOSALS" = "ACCEPTANCE_PROPOSALS",
    "REVIEW_PROPOSALS" = "REVIEW_PROPOSALS",
    "COMPLETED" = "COMPLETED"
}
export type ComparativeTableV2Dto = {
    /**
     * @type integer | undefined int32
    */
    selectedItemsCount?: number;
    /**
     * @type integer | undefined int32
    */
    selectedParticipantsCount?: number;
    selectedSum?: MonetaryAmountDto;
    /**
     * @type integer int32
    */
    itemsCount: number;
    /**
     * @type string
    */
    status: ComparativeTableV2DtoStatus;
    /**
     * @type integer int32
    */
    offersCount: number;
    /**
     * @type array
    */
    rows: PurchaseTenderComparativeRow[];
    /**
     * @type array
    */
    participants: PurchaseParticipantDto[];
    /**
     * @type array
    */
    itemsHeaders: PurchaseItemHeaderDto[];
    /**
     * @type integer int32
    */
    valRate: number;
    /**
     * @type boolean
    */
    exportExcel: boolean;
};
