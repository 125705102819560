import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { UpdateAndPublishAuctionMutationRequest, UpdateAndPublishAuctionMutationResponse, UpdateAndPublishAuctionQueryParams, UpdateAndPublishAuction400, UpdateAndPublishAuction401, UpdateAndPublishAuction403, UpdateAndPublishAuction500 } from "../types/UpdateAndPublishAuction";

/**
 * @description Метод предназначен для редактирования и публикации предложения (аукциона) https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425486310
Действия (выполняются транзакционно): - Валидируем переданные параметры на соответствие аукциону в статусе PUBLISHED. - Сохраняем аукцион в статусе DRAFT - Переводим его в статус PUBLISHED - В результате возвращаем измененный аукцион аналогично методу PUT /auctions/{auctionId}

 * @link /v1/auction/do_update-and-publish
 */

export function useUpdateAndPublishAuction <TData = UpdateAndPublishAuctionMutationResponse, TError = UpdateAndPublishAuction400 | UpdateAndPublishAuction401 | UpdateAndPublishAuction403 | UpdateAndPublishAuction500, TVariables = UpdateAndPublishAuctionMutationRequest>(params: UpdateAndPublishAuctionQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/v1/auction/do_update-and-publish`,
        data,
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
