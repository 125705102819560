import type { ResponsePurchaseTendersResponse } from "./ResponsePurchaseTendersResponse";

export enum GetPurchaseTendersQueryParamsStatus {
    "ALL_TENDER" = "ALL_TENDER",
    "ACTIVE" = "ACTIVE",
    "ARCHIVE" = "ARCHIVE"
}
export type GetPurchaseTendersQueryParams = {
    /**
     * @type string
    */
    status: GetPurchaseTendersQueryParamsStatus;
};

/**
 * @description OK
*/
export type GetPurchaseTendersQueryResponse = ResponsePurchaseTendersResponse;
