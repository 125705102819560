import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Skeleton } from '@/components/ui'
import { EJournalRoute } from '@/types'
import { EJournalRubricsLabel } from '@/types/Journal/'
import { useJournalRubrics } from '@/utils/infoportal/hooks/useJournalRubrics'
import { useJournalUrlParams } from '@/utils/infoportal/hooks/useJournalUrlParams'
import { createRouterLink } from '@/utils/url'
import { rubricsMenuSkeletons } from './constants'
import type { IRubricsMenuProps } from './interfaces'
import './styles.scss'

const RubricsMenu: React.FC<IRubricsMenuProps> = props => {
  const { className } = props
  const { rubricId } = useJournalUrlParams()

  const { rubrics, isJournalRubricsMenuLoading } = useJournalRubrics()

  return (
    <div className="page-wrapper">
      <div className={classNames('rubricsMenu', className)}>
        <Skeleton isLoading={isJournalRubricsMenuLoading} skeletons={rubricsMenuSkeletons}>
          <ul role="navigation" aria-label="Рубрики журнала" className="rubricsMenu__list">
            <li className="text_small rubricsMenu__item rubricsMenu__item--main ">
              <CommonLink className="rubricsMenu__itemLink" exact url={createRouterLink(EJournalRoute.MAIN)}>
                {EJournalRubricsLabel.MAIN}
              </CommonLink>
            </li>
            {rubrics?.map(({ id, name }) => (
              <li key={id} className="text_small rubricsMenu__item">
                <CommonLink
                  className={classNames('rubricsMenu__itemLink', { 'rubricsMenu__itemLink--active': Number(rubricId) === id })}
                  url={createRouterLink(EJournalRoute.RUBRIC, { rubricId: id })}
                >
                  {name}
                </CommonLink>
              </li>
            ))}
          </ul>
        </Skeleton>
      </div>
    </div>
  )
}

export default RubricsMenu
