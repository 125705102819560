import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { FormValuesFormatter } from '@/constants/financing'
import { applyFinancingOffer } from '@/logic/financing/actions'
import type { IApplyingFinancingOfferProps, IFinancingOfferFormValues } from '@/logic/financing/interfaces'
import { getProfile } from '@/logic/profile/reducer'
import type { RootState } from '@/redux/interfaces'
import { formattingMFOFinancingOffer } from '@/utils/formattingMFOFinancingOffer'
import { getApplyingFinancingOfferFormInitialValues } from '@/utils/getApplyingFinancingOfferFormInitialValues'
import { useRegionsMicrocreditOrganizations } from '@/utils/hooks/applyingFinancingOffer/useRegionsMicrocreditOrganizations'
import { isApplyingOfferRequestBody } from './typeguard'

type TApplyingFinancingOfferFormOptions = {
  formValues: Partial<IFinancingOfferFormValues>
  onBackAfterError?: () => void
}

const mapState = (state: RootState) => ({
  profile: getProfile()(state),
})

export const useApplyingFinancingOfferForm = ({ offerType, productId }: IApplyingFinancingOfferProps) => {
  const dispatch = useDispatch()
  const { profile } = useSelector(mapState)
  const { isAuthenticated } = useAuthenticateUser()

  const { isMFOProduct, regionsAvailableExpressMFO } = useRegionsMicrocreditOrganizations({
    offerType,
    productId,
  })
  const [options, setOptions] = useState<TApplyingFinancingOfferFormOptions>({ formValues: {} })

  useEffect(() => {
    if (Object.keys(options.formValues || {}).length > 0 && isAuthenticated) {
      const initialValues = getApplyingFinancingOfferFormInitialValues({
        offerType,
        productId,
        profile,
        formValues: options.formValues,
      })

      const body = FormValuesFormatter[offerType]?.(initialValues as IFinancingOfferFormValues) || initialValues

      if (isApplyingOfferRequestBody(body)) {
        if (isMFOProduct) {
          formattingMFOFinancingOffer({
            regionsAvailableExpressMFO,
            body,
            dispatch,
            onBackAfterError: options.onBackAfterError,
          })
        } else {
          dispatch(applyFinancingOffer({ body, offerType, onBackAfterError: options.onBackAfterError }))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerType, options, isAuthenticated])

  return {
    dispatch,
    profile,
    formValues: options.formValues,
    setOptions,
  }
}
