import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateValueFieldsPublicMutationRequest, UpdateValueFieldsPublicMutationResponse, UpdateValueFieldsPublicQueryParams } from "../../types/UpdateValueFieldsPublic";

/**
     * @link /api/purchase-tenders/pub/v1/update_value-fields
     */
export async function updateValueFieldsPublic (data: UpdateValueFieldsPublicMutationRequest, params: UpdateValueFieldsPublicQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateValueFieldsPublicMutationResponse>["data"]> {
    const { data: resData } = await client<UpdateValueFieldsPublicMutationResponse, UpdateValueFieldsPublicMutationRequest>({
        method: "put",
        url: `/api/purchase-tenders/pub/v1/update_value-fields`,
        params,
        data,
        ...options
    });
    
    return resData;
};