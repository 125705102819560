import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { FindPublicPurchaseTendersByParamsQueryResponse, FindPublicPurchaseTendersByParamsQueryParams } from "../types/FindPublicPurchaseTendersByParams";

export const findPublicPurchaseTendersByParamsQueryKey = (params?: FindPublicPurchaseTendersByParamsQueryParams) =>     [{url:`/api/purchase-tenders/pub/v1/tenders`},...(params ? [params] : [])] as const;
      export function findPublicPurchaseTendersByParamsQueryOptions <TData = FindPublicPurchaseTendersByParamsQueryResponse, TError = unknown>(params?: FindPublicPurchaseTendersByParamsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = findPublicPurchaseTendersByParamsQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/purchase-tenders/pub/v1/tenders`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/purchase-tenders/pub/v1/tenders
 */

export function useFindPublicPurchaseTendersByParams <TData = FindPublicPurchaseTendersByParamsQueryResponse, TError = unknown>(params?: FindPublicPurchaseTendersByParamsQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? findPublicPurchaseTendersByParamsQueryKey(params);

  const query = useQuery<TData, TError>({
    ...findPublicPurchaseTendersByParamsQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
