import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdatePurchaseTenderMutationRequest, UpdatePurchaseTenderMutationResponse, UpdatePurchaseTenderQueryParams } from "../../types/UpdatePurchaseTender";

/**
     * @link /api/purchase-tenders/v1/update_tender
     */
export async function updatePurchaseTender (data: UpdatePurchaseTenderMutationRequest, params: UpdatePurchaseTenderQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdatePurchaseTenderMutationResponse>["data"]> {
    const { data: resData } = await client<UpdatePurchaseTenderMutationResponse, UpdatePurchaseTenderMutationRequest>({
        method: "post",
        url: `/api/purchase-tenders/v1/update_tender`,
        params,
        data,
        ...options
    });
    
    return resData;
};