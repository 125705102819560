import type { ItemFeature } from "./ItemFeature";
import type { MonetaryAmountDto } from "./MonetaryAmountDto";

export enum BidItemRequestStatusBid {
    "NEW" = "NEW",
    "CANCELED" = "CANCELED"
}
export enum BidItemRequestFieldsMode {
    "ANALOGUE" = "ANALOGUE",
    "ORIGINAL" = "ORIGINAL"
}
export type BidItemRequest = {
    /**
     * @type integer int64
    */
    tenderItemId: number;
    /**
     * @type array
    */
    features: ItemFeature[];
    price: MonetaryAmountDto;
    /**
     * @type string
    */
    statusBid: BidItemRequestStatusBid;
    /**
     * @type string | undefined
    */
    fieldsMode?: BidItemRequestFieldsMode;
};
