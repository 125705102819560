import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CancelPurchaseTenderMutationResponse, CancelPurchaseTenderQueryParams } from "../types/CancelPurchaseTender";

/**
 * @link /api/purchase-tenders/v1/tender
 */

export function useCancelPurchaseTender <TData = CancelPurchaseTenderMutationResponse, TError = unknown>(params: CancelPurchaseTenderQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "delete",
        url: `/api/purchase-tenders/v1/tender`,
        
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
