import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateLostMutationRequest, CreateLostMutationResponse, CreateLost400, CreateLost401, CreateLost403, CreateLost422, CreateLost429, CreateLost500 } from "../types/CreateLost";

/**
 * @link /api/credit-flow-backend/private/v1/funding/do_create-lost
 */

export function useCreateLost <TData = CreateLostMutationResponse, TError = CreateLost400 | CreateLost401 | CreateLost403 | CreateLost422 | CreateLost429 | CreateLost500, TVariables = CreateLostMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/credit-flow-backend/private/v1/funding/do_create-lost`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
