import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { CreateAuctionPlannedMutationRequest, CreateAuctionPlannedMutationResponse, CreateAuctionPlanned400, CreateAuctionPlanned401, CreateAuctionPlanned403, CreateAuctionPlanned500 } from "../types/CreateAuctionPlanned";

/**
 * @description Метод предназначен для создания планновых предложения (аукциона) https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425486301

 * @link /v1/auction/do_create-planned
 */

export function useCreateAuctionPlanned <TData = CreateAuctionPlannedMutationResponse, TError = CreateAuctionPlanned400 | CreateAuctionPlanned401 | CreateAuctionPlanned403 | CreateAuctionPlanned500, TVariables = CreateAuctionPlannedMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/v1/auction/do_create-planned`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
