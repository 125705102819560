import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { SaveDocumentMutationResponse, SaveDocumentQueryParams } from "../../types/SaveDocument";

/**
     * @link /api/purchase-tenders/v1/save_document
     */
export async function saveDocument (params: SaveDocumentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SaveDocumentMutationResponse>["data"]> {
    const { data: resData } = await client<SaveDocumentMutationResponse>({
        method: "post",
        url: `/api/purchase-tenders/v1/save_document`,
        params,
        ...options
    });
    
    return resData;
};