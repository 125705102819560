
export enum UpdateValueFieldsScreen {
    "ADD_ITEMS" = "ADD_ITEMS",
    "SUBMIT_BID" = "SUBMIT_BID"
}
export enum UpdateValueFieldsFieldsMode {
    "ANALOGUE" = "ANALOGUE",
    "ORIGINAL" = "ORIGINAL"
}
export type UpdateValueFields = {
    /**
     * @type integer int64
    */
    itemId: number;
    /**
     * @type string
    */
    screen: UpdateValueFieldsScreen;
    /**
     * @type object
    */
    bidFeatureValues: {
        [key: string]: string;
    };
    /**
     * @type string | undefined
    */
    fieldsMode?: UpdateValueFieldsFieldsMode;
};

import type { ResponseMapLongFieldsResponse } from "./ResponseMapLongFieldsResponse";

export type UpdateValueFieldsQueryParams = {
    /**
     * @type string
    */
    tenderId: string;
};

export type UpdateValueFieldsMutationRequest = UpdateValueFields;

/**
 * @description OK
*/
export type UpdateValueFieldsMutationResponse = ResponseMapLongFieldsResponse;
