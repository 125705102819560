import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteItemMutationResponse, DeleteItemQueryParams } from "../../types/DeleteItem";

/**
     * @link /api/purchase-tenders/v1/item
     */
export async function deleteItem (params: DeleteItemQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteItemMutationResponse>["data"]> {
    const { data: resData } = await client<DeleteItemMutationResponse>({
        method: "delete",
        url: `/api/purchase-tenders/v1/item`,
        params,
        ...options
    });
    
    return resData;
};