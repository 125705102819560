import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateItemMutationRequest, UpdateItemMutationResponse, UpdateItemQueryParams } from "../../types/UpdateItem";

/**
     * @link /api/purchase-tenders/v1/item
     */
export async function updateItem (data: UpdateItemMutationRequest, params: UpdateItemQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateItemMutationResponse>["data"]> {
    const { data: resData } = await client<UpdateItemMutationResponse, UpdateItemMutationRequest>({
        method: "put",
        url: `/api/purchase-tenders/v1/item`,
        params,
        data,
        ...options
    });
    
    return resData;
};